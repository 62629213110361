import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/sidebar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import MemberGoal from './memberGoal';
import MemberAttendance from "./memberAttendance";
import MemberTransactions from './MemberTransactions';

const MemberDetail = () => {
  const { gymId, memberId } = useParams();
  const [memberData, setMemberData] = useState(null);
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('info');
  const [isEditing, setIsEditing] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch member details
  useEffect(() => {
    const fetchMemberDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}users/gym-member/${gymId}/${memberId}/`, {
          headers: { Authorization: `Token ${token}` },
        });
        setMemberData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching member details:', error);
        setLoading(false);
      }
    };

    fetchMemberDetails();
  }, [API_URL, gymId, memberId]);

  // Fetch packages
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}packages/`, {
          headers: { Authorization: `Token ${token}` },
        });
        setPackages(response.data);
      } catch (error) {
        console.error('Error fetching packages:', error);
      }
    };

    fetchPackages();
  }, [API_URL]);

  const handlePackageAssignment = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_URL}packages/assign-package/${memberId}/`,
        { package_id: selectedPackage },
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      toast.success('Package successfully assigned!');
      setIsEditing(false);
      window.location.reload();
    } catch (error) {
      console.error('Error assigning package:', error);
      toast.error('Failed to assign package.');
    }
  };

  const handleEditPackage = () => {
    setSelectedPackage(memberData.package?.id || '');
    setIsEditing(true);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!memberData) {
    return <p>No member data available.</p>;
  }

  const { first_name, last_name, email, phone_number, DOB, user_address, email_verified, package: memberPackage, goals } = memberData;

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        <header className="flex items-center mb-6">
          <img
            src={memberData.profile_photo_path || 'https://placehold.co/100x100'}
            alt={`${first_name} ${last_name}`}
            className="rounded-full mr-4"
            style={{ width: '100px', height: '100px' }}
          />
          <h1 className="text-2xl font-bold">{`${first_name} ${last_name}`}</h1>
        </header>
        <nav className="mb-6">
          <ul className="flex space-x-4">
            <li>
              <button
                className={`py-2 px-4 ${activeTab === 'info' ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded hover:bg-blue-400`}
                onClick={() => setActiveTab('info')}
              >
                Member Info
              </button>
            </li>
            <li>
              <button
                className={`py-2 px-4 ${activeTab === 'package' ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded hover:bg-gray-300`}
                onClick={() => setActiveTab('package')}
              >
                Member Package
              </button>
            </li>
            <li>
              <button
                className={`py-2 px-4 ${activeTab === 'goals' ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded hover:bg-gray-300`}
                onClick={() => setActiveTab('goals')}
              >
                Member Goals
              </button>
            </li>
            <li>
                <button
                  className={`py-2 px-4 ${activeTab === 'attendance' ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded hover:bg-gray-300`}
                  onClick={() => setActiveTab('attendance')}
                >
                  Member Attendance
                </button>
              </li>
              <li>
                <button
                  className={`py-2 px-4 ${activeTab === 'transactions' ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded hover:bg-gray-300`}
                  onClick={() => setActiveTab('transactions')}
                >
                  Member Transactions
                </button>
              </li>
          </ul>
        </nav>

        <section className="bg-white p-4 rounded-lg shadow">
          {/* Info Tab */}
          {activeTab === 'info' && (
            <>
              <h2 className="text-xl font-bold mb-4">Member Info</h2>
              <p className="mb-2">
                <strong>Email:</strong> {email}
              </p>
              <p className="mb-2">
                <strong>Phone Number:</strong> {phone_number}
              </p>
              <p className="mb-2">
                <strong>Joined On:</strong> {new Date(memberData.created_at).toLocaleDateString()}
              </p>
              <p className="mb-2">
                <strong>Status:</strong> {email_verified ? 'Active' : 'Inactive'}
              </p>
              <p className="mb-2">
                <strong>DOB:</strong> {DOB}
              </p>
              <p className="mb-2">
                <strong>Area:</strong> {user_address}
              </p>
            </>
          )}

          {/* Package Tab */}
          {activeTab === 'package' && memberPackage && !isEditing && (
            <>
              <h2 className="text-xl font-bold mb-4">Member Package</h2>
              <p className="mb-2">
                <strong>Package Name:</strong> {memberPackage.package_name}
              </p>
              <p className="mb-2">
                <strong>Description:</strong> {memberPackage.package_description}
              </p>
              <p className="mb-2">
                <strong>Amount:</strong> {memberPackage.amount}
              </p>
              <p className="mb-2">
                <strong>Duration:</strong> {memberPackage.duration}{" "}
                  {memberPackage.package_type === "daily"
                    ? "day(s)"
                    : memberPackage.package_type === "weekly"
                    ? "week(s)"
                    : "month(s)"}
              </p>
              <button onClick={handleEditPackage} className="text-blue-500 hover:underline">
                Edit
              </button>
            </>
          )}

          {activeTab === 'package' && (isEditing || !memberPackage) && (
            <>
              <h2 className="text-xl font-bold mb-4">Assign/Edit Package</h2>
              <select
                value={selectedPackage}
                onChange={(e) => setSelectedPackage(e.target.value)}
                className="mb-4 p-2 border rounded"
              >
                <option value="">Select a package</option>
                {packages.map((pkg) => (
                  <option key={pkg.id} value={pkg.id}>
                  {pkg.package_name} - Ksh {pkg.amount} - {pkg.duration}{" "}
                  {pkg.package_type === "daily"
                    ? "day(s)"
                    : pkg.package_type === "weekly"
                    ? "week(s)"
                    : "month(s)"}
                  </option>
                ))}
              </select>
              <button
                onClick={handlePackageAssignment}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
                disabled={!selectedPackage}
              >
                {memberPackage ? 'Update' : 'Add'}
              </button>
            </>
          )}

          {/* Goals Tab */}
          {activeTab === 'goals' && (
            <MemberGoal
              goals={goals}
              memberId={memberId}
              gymId={gymId}
              onGoalSave={() => {
                window.location.reload();
              }}
            />
          )}
          {/* Attendance Tab */}
            {activeTab === "attendance" && <MemberAttendance />}
           {/* Transactions Tab */}  
            {activeTab === "transactions" && <MemberTransactions />}
        </section>
      </main>
    </div>
  );
};

export default MemberDetail;