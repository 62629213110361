import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Sidebar from '../Sidebar/sidebar';

const WorkoutTemplateDetail = () => {
  const { id } = useParams();  
  const [workoutTemplate, setWorkoutTemplate] = useState(null);  
  const [exercises, setExercises] = useState([]);  
  const [allExercises, setAllExercises] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredExercises, setFilteredExercises] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL; 

  useEffect(() => {
    fetchWorkoutTemplateDetails();
    fetchAllExercises();
  }, [id]);

  const fetchWorkoutTemplateDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}workouts/templates/${id}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setWorkoutTemplate(response.data);
      setExercises(response.data.exercises);
    } catch (err) {
      console.error('Error fetching workout template details:', err);
      setError('Failed to fetch workout template details. Please try again later.');
    }
  };

  const fetchAllExercises = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}workouts/1/exercises/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setAllExercises(response.data);
      setFilteredExercises(response.data);
    } catch (err) {
      console.error('Error fetching exercises:', err);
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term) {
      const filtered = allExercises.filter((exercise) =>
        exercise.exercise_name && exercise.exercise_name.toLowerCase().includes(term)
      );
      setFilteredExercises(filtered);
    } else {
      setFilteredExercises(allExercises); 
    }
  };

  const handleExerciseSelect = (exercise) => {
    if (!exercises.some((ex) => ex.exercise.id === exercise.id)) {
      setExercises((prevExercises) => [
        ...prevExercises,
        { exercise: exercise },
      ]);
    }
  };

  const handleExerciseRemove = (exerciseId) => {
    setExercises((prevExercises) =>
      prevExercises.filter((ex) => ex.exercise.id !== exerciseId)
    );
  };

  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setWorkoutTemplate((prevTemplate) => ({
      ...prevTemplate,
      description: value,
    }));
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setWorkoutTemplate((prevTemplate) => ({
      ...prevTemplate,
      name: value,
    }));
  };
  const handleUpdateWorkoutTemplate = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${API_URL}workouts/templates/${id}/`,
        {
          name: workoutTemplate.name,
          description: workoutTemplate.description,
          exercises: exercises.map((ex) => ex.exercise.id),
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setShowUpdateModal(false);
      fetchWorkoutTemplateDetails();  
    } catch (err) {
      console.error('Error updating workout template:', err);
      setError('Failed to update workout template. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        {error && <p className="text-red-500 mb-4">{error}</p>}
        
        {workoutTemplate && (
          <>
            <h2 className="text-2xl font-bold mb-4">Workout Template Details</h2>
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
              <div className="flex items-center mb-4">
                <div>
                  <h3 className="text-xl font-semibold">{workoutTemplate.name}</h3>
                  <p className="text-gray-600">Created By: {workoutTemplate.created_by_name}</p>
                  <p className="text-gray-600">Description: {workoutTemplate.description}</p>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Exercises List</h3>
              <button 
                className="bg-black text-white py-2 px-4 rounded hover:bg-gray-800 transition-all duration-300 ease-in-out"
                onClick={() => setShowUpdateModal(true)}
              >
                Update Workout Template
              </button>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full bg-white shadow-lg rounded-lg">
                <thead>
                  <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th className="py-3 px-6 text-left">Exercise Image</th>
                    <th className="py-3 px-6 text-left">Exercise Name</th>
                    <th className="py-3 px-6 text-left">Muscle Group</th>
                    <th className="py-3 px-6 text-left">Created By</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  {exercises.map((exercise) => (
                    <tr key={exercise.id} className="border-b border-gray-200 hover:bg-gray-100">
                      <td className="py-3 px-6">
                        <img 
                          src={exercise.exercise.profile_photo_path || "https://www.tailwindai.dev/placeholder.svg"} 
                          alt="Exercise" 
                          className="w-16 h-16 rounded"
                        />
                      </td>
                      <td className="py-3 px-6">{exercise.exercise.exercise_name}</td>
                      <td className="py-3 px-6">{exercise.exercise.muscle_group || 'N/A'}</td>  
                      <td className="py-3 px-6">{exercise.exercise.created_by_name || 'Unknown'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {/* Update Modal */}
        {showUpdateModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
              <button
                className="absolute top-2 right-2 text-gray-600 text-2xl hover:text-gray-800"
                onClick={() => setShowUpdateModal(false)}
              >
                &times;
              </button>
              <div className="mt-3">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Update Workout Template</h3>
                <form onSubmit={handleUpdateWorkoutTemplate}>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Name</label>
                    <input
                    type="text"
                    name="name"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    value={workoutTemplate.name}
                    onChange={handleNameChange}
                    required
                  />
                    <label className="block text-sm font-medium text-gray-700">Description</label>
                    <textarea
                      name="description"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      value={workoutTemplate.description}
                      onChange={handleDescriptionChange}
                      rows="3"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Search Exercise</label>
                    <input
                      type="text"
                      name="searchExercise"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      placeholder="Search for an exercise"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <div className="mt-2 max-h-40 overflow-y-auto">
                      {filteredExercises.map((exercise) => (
                        <div
                          key={exercise.id}
                          className="cursor-pointer bg-gray-200 p-2 mb-1 rounded hover:bg-gray-300"
                          onClick={() => handleExerciseSelect(exercise)}
                        >
                          {exercise.exercise_name}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mb-4 flex flex-wrap gap-2" id="selected-exercises">
                    {exercises.map((exercise) => (
                      <span key={exercise.exercise.id} className="bg-blue-100 text-blue-800 text-sm font-medium px-2.5 py-0.5 rounded-full flex items-center">
                        {exercise.exercise.exercise_name}
                        <button
                          type="button"
                          className="ml-1 text-blue-500 hover:text-blue-700 text-lg"
                          onClick={() => handleExerciseRemove(exercise.exercise.id)}
                        >
                          &times;
                        </button>
                      </span>
                    ))}
                  </div>
                  <div className="flex justify-end gap-2">
                    <button
                      type="button"
                      className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-500"
                      onClick={() => setShowUpdateModal(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400">
                      Update Workout Template
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default WorkoutTemplateDetail;