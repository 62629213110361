import React, { useState, useEffect } from "react";
import axios from "axios";

const Transactions = ({ showTransactionModal, setShowTransactionModal }) => {
  const [transactions, setTransactions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [memberSearch, setMemberSearch] = useState("");
  const [memberResults, setMemberResults] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [paymentDate, setPaymentDate] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [monthlyAmountExpected, setMonthlyAmountExpected] = useState("N/A");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}transactions/`, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      setTransactions(response.data);
    } catch (err) {
      console.error("Error fetching transactions:", err);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "2-digit",
      year: "numeric",
    });
  };

  const toggleModal = () => {
    setSelectedMember(null);
    setMemberSearch("");
    setPaymentDate("");
    setAmountPaid("");
    setPaymentMethod("");
    setMonthlyAmountExpected("N/A");
    setErrorMessage("");
    setShowTransactionModal(false)
  };

  const handleMemberSearch = async (e) => {
    setMemberSearch(e.target.value);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${API_URL}users/search-members/?query=${e.target.value}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setMemberResults(response.data);
    } catch (err) {
      console.error("Error searching members:", err);
    }
  };

  const handleSelectMember = (member) => {
    setSelectedMember(member);
    setMemberSearch(`${member.first_name} ${member.last_name}`);
    setMemberResults([]);

    if (member.package) {
      setMonthlyAmountExpected(`Ksh ${member.package.amount}`);
    } else {
      setMonthlyAmountExpected("N/A");
    }
  };

  const handleCreateTransaction = async () => {
    if (!selectedMember || !paymentDate || !amountPaid || !paymentMethod) {
      setErrorMessage("Please fill out all required fields.");
      return;
    }
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const data = {
        gym_member_id: selectedMember.id,
        payment_date: paymentDate,
        amount_paid: amountPaid,
        payment_method: paymentMethod,
      };

      await axios.post(`${API_URL}transactions/create/`, data, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      fetchTransactions();
      toggleModal();
    } catch (err) {
      if (err.response && err.response.status === 400 && err.response.data.error) {
        setErrorMessage(err.response.data.error);
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const getCurrentItems = (items, currentPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  };

  const paginatedTransactions = getCurrentItems(transactions, currentPage);

  return (
    <div className="flex">
      <main className="flex-1 w-full pb-6">
        <div className="overflow-x-auto w-full ">
          <table className="min-w-full bg-white shadow-lg rounded-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Member Name</th>
                <th className="py-3 px-6 text-left">Amount Paid</th>
                <th className="py-3 px-6 text-left">Balance</th>
                <th className="py-3 px-6 text-left">Payment Date</th>
                <th className="py-3 px-6 text-left">Payment Method</th>
                <th className="py-3 px-6 text-left">Action</th> 
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {paginatedTransactions.map((transaction, index) => (
                <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6">{transaction.member_name}</td>
                  <td className="py-3 px-6">Ksh {transaction.amount_paid}</td>
                  <td className="py-3 px-6">Ksh {transaction.balance}</td>
                  <td className="py-3 px-6">{formatDate(transaction.payment_date)}</td>
                  <td className="py-3 px-6">{transaction.payment_method}</td>
                  <td className="py-3 px-6">
                    {transaction.balance > 0 && (
                      <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400 transition-all duration-300">
                        Remind
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-center mt-4">
            <button
              className={`px-3 py-1 border rounded-md ${currentPage > 1 ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-700 cursor-not-allowed"}`}
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            >
              Previous
            </button>
            <span className="mx-3 text-sm">
              Page {currentPage} of {Math.ceil(transactions.length / itemsPerPage)}
            </span>
            <button
              className={`px-3 py-1 border rounded-md ${currentPage < Math.ceil(transactions.length / itemsPerPage) ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-700 cursor-not-allowed"}`}
              disabled={currentPage === Math.ceil(transactions.length / itemsPerPage)}
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(transactions.length / itemsPerPage)))}
            >
              Next
            </button>
          </div>
        </div>

        {showTransactionModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-xl font-bold mb-4">Create Transaction</h2>

              {/* Member Search */}
              <label className="block text-gray-700 mb-2">Member</label>
              <input 
                type="text" 
                value={memberSearch} 
                onChange={handleMemberSearch} 
                placeholder="Search for member..." 
                className="w-full border p-2 rounded mb-2" 
              />
              {/* Show search results */}
              {memberResults.length > 0 && (
                <div className="bg-white border rounded shadow-md max-h-32 overflow-y-auto">
                  {memberResults.map((member) => (
                    <div 
                      key={member.id} 
                      onClick={() => handleSelectMember(member)} 
                      className="p-2 cursor-pointer hover:bg-gray-200"
                    >
                      {member.first_name} {member.last_name}
                    </div>
                  ))}
                </div>
              )}

              {/* Monthly Amount Expected */}
              <label className="block text-gray-700 mb-2">Expected Collection</label>
              <input 
                type="text" 
                value={monthlyAmountExpected} 
                readOnly 
                className="w-full border p-2 rounded mb-2" 
              />

              {/* Payment Date */}
              <label className="block text-gray-700 mb-2">Payment Date</label>
              <input 
                type="date" 
                value={paymentDate} 
                onChange={(e) => setPaymentDate(e.target.value)} 
                className="w-full border p-2 rounded mb-2" 
              />

              {/* Amount Paid */}
              <label className="block text-gray-700 mb-2">Amount Paid</label>
              <input 
                type="number" 
                value={amountPaid} 
                onChange={(e) => setAmountPaid(e.target.value)} 
                className="w-full border p-2 rounded mb-2" 
              />

              {/* Payment Method Dropdown */}
              <label className="block text-gray-700 mb-2">Payment Method</label>
              <select 
                value={paymentMethod} 
                onChange={(e) => setPaymentMethod(e.target.value)} 
                className="w-full border p-2 rounded mb-2"
              >
                <option value="">Select Payment Method</option>
                <option value="Mpesa">Mpesa</option>
                <option value="Cash">Cash</option>
                <option value="Card">Card</option>
              </select>

              {/* Display error message if any */}
              {errorMessage && (
                <div className="text-red-500 text-sm mb-2">
                  ⚠️ {errorMessage}
                </div>
              )}

              {/* Buttons */}
              <div className="flex justify-end mt-4">
                <button 
                  onClick={toggleModal} 
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-400 mr-2"
                >
                  Cancel
                </button>
                <button 
                  onClick={handleCreateTransaction} 
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
                >
                  {loading ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Transactions;