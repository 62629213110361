import React from "react"

/**
 * Basic Card container
 */
function Card({ children, className = "", ...props }) {
  return (
    <div
      className={[
        "rounded-lg bg-white shadow",
        className,
      ].join(" ")}
      {...props}
    >
      {children}
    </div>
  )
}

/**
 * CardHeader: top section of the Card (often holds a title)
 */
function CardHeader({ children, className = "", ...props }) {
  return (
    <div
      className={[
        "p-4 border-b border-gray-200",
        className,
      ].join(" ")}
      {...props}
    >
      {children}
    </div>
  )
}

/**
 * CardTitle: typically used inside CardHeader
 */
function CardTitle({ children, className = "", ...props }) {
  return (
    <h2
      className={[
        "text-lg font-semibold",
        className,
      ].join(" ")}
      {...props}
    >
      {children}
    </h2>
  )
}

/**
 * CardContent: main body of the Card
 */
function CardContent({ children, className = "", ...props }) {
  return (
    <div
      className={[
        "p-4",
        className,
      ].join(" ")}
      {...props}
    >
      {children}
    </div>
  )
}

export { Card, CardHeader, CardTitle, CardContent }