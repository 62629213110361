import React from "react"

/**
 * A small badge/pill for highlighting tags or labels
 */
function Badge({ children, className = "", ...props }) {
  return (
    <span
      className={[
        "inline-flex items-center rounded-full",
        "bg-primary/10 px-2 py-1 text-xs font-medium text-primary",
        className,
      ].join(" ")}
      {...props}
    >
      {children}
    </span>
  )
}

export { Badge }