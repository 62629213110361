import React from "react"

/**
 * Minimal Button component with Tailwind variants.
 * Adjust classes or add more variants as you like.
 */
function Button({
  children,
  variant = "default",
  size = "md",
  className = "",
  ...props
}) {
  // Basic variant classes
  let variantClasses
  switch (variant) {
    case "outline":
      variantClasses = "border border-primary text-primary bg-transparent hover:bg-primary hover:text-white"
      break
    case "ghost":
      variantClasses = "bg-transparent text-primary hover:bg-primary/10"
      break
    default: // "default"
      variantClasses = "bg-primary text-white hover:bg-primary/90"
      break
  }

  // Basic size classes
  let sizeClasses
  switch (size) {
    case "sm":
      sizeClasses = "px-3 py-1 text-sm"
      break
    case "lg":
      sizeClasses = "px-6 py-3 text-lg"
      break
    case "icon":
      sizeClasses = "p-2"
      break
    default: // "md"
      sizeClasses = "px-4 py-2 text-sm"
      break
  }

  return (
    <button
      className={[
        "inline-flex items-center justify-center rounded-md font-medium transition-colors",
        "focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 disabled:opacity-50",
        variantClasses,
        sizeClasses,
        className,
      ].join(" ")}
      {...props}
    >
      {children}
    </button>
  )
}

export { Button }