import React from 'react';
import Routes from './components/Routes/routes';
import { NotificationContainer } from 'react-notifications';
import { Toaster } from 'react-hot-toast';  
import { AuthProvider } from './components/Routes/AuthContext'; 

function App() {
  return (
    <div className="App">
      <NotificationContainer />  
      <Toaster position="top-right" />  
      
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </div>
  );
}

export default App;