import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Sidebar/sidebar";
import Transactions from "./Transactions";

const Billings = () => {
  const [activeTab, setActiveTab] = useState("billings");
  const [billings, setBillings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [memberSearch, setMemberSearch] = useState("");
  const [memberResults, setMemberResults] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [billingDate, setBillingDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentBillingPage, setCurrentBillingPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const itemsPerPage = 10;
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (activeTab === "billings") {
      fetchBillings();
    }
  }, [activeTab]);

  const fetchBillings = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}billings/`, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      const updatedBillings = response.data.map((billing) => ({
        ...billing,
        payment_status:
          billing.balance === 0
            ? "Paid"
            : billing.payment_status === "Not Paid" &&
              new Date(billing.billing_date) < new Date()
            ? "Overdue"
            : billing.payment_status,
      }));
      setBillings(updatedBillings);
    } catch (err) {
      console.error("Error fetching billings:", err);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "2-digit",
      year: "numeric",
    });
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    setSelectedMember(null);
    setSelectedPackage(null);
    setMemberSearch("");
    setBillingDate("");
    setErrorMessage("");
  };

  const handleMemberSearch = async (e) => {
    setMemberSearch(e.target.value);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${API_URL}users/search-members/?query=${e.target.value}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setMemberResults(response.data);
    } catch (err) {
      console.error("Error searching members:", err);
    }
  };

  const handleSelectMember = (member) => {
    setSelectedMember(member);
    setMemberSearch(`${member.first_name} ${member.last_name}`);
    setMemberResults([]);

    if (member.package) {
      setSelectedPackage(member.package);
      setErrorMessage("");
    } else {
      setSelectedPackage(null);
      setErrorMessage("Selected member does not have an assigned package.");
    }
  };

  const handleSendReminder = (billingId) => {
    setBillings((prevBillings) =>
      prevBillings.map((billing) =>
        billing.id === billingId ? { ...billing, reminder_sent: true } : billing
      )
    );
  };

  const handleCreateBilling = async () => {
    if (!selectedMember) {
      setErrorMessage("⚠️ Please select a member.");
      return;
    }

    if (!selectedPackage) {
      setErrorMessage("⚠️ Selected member does not have an assigned package.");
      return;
    }

    if (!billingDate) {
      setErrorMessage("⚠️ Please select a billing date.");
      return;
    }

    setLoading(true); 

    try {
      const token = localStorage.getItem("token");
      const gymId = selectedMember.gym;

      const data = {
        gym_member_id: selectedMember.id,
        gym_id: gymId,
        billing_date: billingDate,
        amount: selectedPackage.amount,
        payment_status: "Not Paid",
      };

      await axios.post(`${API_URL}billings/create/`, data, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      fetchBillings();
      toggleModal();
    } catch (err) {
      if (err.response && err.response.status === 400 && err.response.data.error) {
        setErrorMessage(err.response.data.error);
      } else {
        setErrorMessage("⚠️ An error occurred. Please try again.");
      }
    } finally {
      setLoading(false); 
    }
};

  const getCurrentItems = (items, currentPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  };

  const paginatedBillings = getCurrentItems(billings, currentBillingPage);

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Billings Overview</h1>
          {activeTab === "billings" ? (
            <button
              onClick={toggleModal}
              className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800 transition-all duration-300"
            >
              Create Billing
            </button>
          ) : (
            <button
                onClick={() => setShowTransactionModal(true)}  
                className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800 transition-all duration-300"
              >
              Create Transaction
            </button>
          )}
        </div>

        {/* Tabs for Billings and Transactions */}
        <div className="mb-4 flex space-x-4">
          <button
            className={`py-2 px-4 rounded-lg ${
              activeTab === "billings" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-600"
            }`}
            onClick={() => setActiveTab("billings")}
          >
            Billings
          </button>
          <button
            className={`py-2 px-4 rounded-lg ${
              activeTab === "transactions" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-600"
            }`}
            onClick={() => setActiveTab("transactions")}
          >
            Transactions
          </button>
        </div>

        {/* Billing Table or Transactions Component */}
        {activeTab === "billings" ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-lg rounded-lg">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm">
                  <th className="py-3 px-6 text-left">Member Name</th>
                  <th className="py-3 px-6 text-left">Package Name</th>
                  <th className="py-3 px-6 text-left">Billing Date</th>
                  <th className="py-3 px-6 text-left">Amount</th>
                  <th className="py-3 px-6 text-left">Payment Status</th>
                  <th className="py-3 px-6 text-left">Action</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {paginatedBillings.map((billing, index) => (
                  <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6">{billing.member_name}</td>
                    <td className="py-3 px-6">{billing.package_name}</td>
                    <td className="py-3 px-6">{formatDate(billing.billing_date)}</td>
                    <td className="py-3 px-6">Ksh {billing.amount_due}</td>
                    <td className="py-3 px-6">
                      <span
                        className={`py-1 px-3 rounded-full text-xs font-semibold ${
                          billing.payment_status === "Paid"
                            ? "bg-green-200 text-green-600"
                            : billing.payment_status === "Partial"
                            ? "bg-orange-200 text-orange-600"
                            : "bg-red-200 text-red-600"
                        }`}
                      >
                        {billing.payment_status}
                      </span>
                    </td>
                    <td className="py-3 px-6">
                      {["Partial", "Overdue"].includes(billing.payment_status) && !billing.reminder_sent && (
                        <button
                          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400 transition-all duration-300"
                          onClick={() => handleSendReminder(billing.id)}
                        >
                          Remind
                        </button>
                      )}
                      {billing.reminder_sent && (
                        <button
                          disabled
                          className="bg-gray-300 text-gray-600 px-4 py-2 rounded cursor-not-allowed"
                        >
                          Reminder Sent
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <Transactions 
            showTransactionModal={showTransactionModal} 
            setShowTransactionModal={setShowTransactionModal}
    />
        )}

        {/* Pagination - Only for Billings */}
        {activeTab === "billings" && (
          <div className="flex justify-center mt-4">
            <button
              className={`px-3 py-1 border rounded-md ${
                currentBillingPage > 1 ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-700 cursor-not-allowed"
              }`}
              disabled={currentBillingPage === 1}
              onClick={() => setCurrentBillingPage((prev) => Math.max(prev - 1, 1))}
            >
              Previous
            </button>
            <span className="mx-3 text-sm">
              Page {currentBillingPage} of {Math.ceil(billings.length / itemsPerPage)}
            </span>
            <button
              className={`px-3 py-1 border rounded-md ${
                currentBillingPage < Math.ceil(billings.length / itemsPerPage)
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-700 cursor-not-allowed"
              }`}
              disabled={currentBillingPage === Math.ceil(billings.length / itemsPerPage)}
              onClick={() =>
                setCurrentBillingPage((prev) =>
                  Math.min(prev + 1, Math.ceil(billings.length / itemsPerPage))
                )
              }
            >
              Next
            </button>
          </div>
        )}

        {/* Create Billing Modal */}
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-xl font-bold mb-4">Create Billing</h2>
              <input
                type="text"
                value={memberSearch}
                onChange={handleMemberSearch}
                placeholder="Search for member..."
                className="w-full border p-2 rounded mb-2"
              />
              {memberResults.length > 0 && (
                <div className="bg-white border rounded shadow-md max-h-32 overflow-y-auto">
                  {memberResults.map((member) => (
                    <div
                      key={member.id}
                      onClick={() => handleSelectMember(member)}
                      className="p-2 cursor-pointer hover:bg-gray-200"
                    >
                      {member.first_name} {member.last_name}
                    </div>
                  ))}
                </div>
              )}
              <input
                type="date"
                value={billingDate}
                onChange={(e) => setBillingDate(e.target.value)}
                className="w-full border p-2 rounded mb-2"
              />
              {errorMessage && (
                <div className="text-red-500 text-sm mb-2">⚠️ {errorMessage}</div>
              )}

              <div className="flex justify-between mt-4 space-x-2">
                <button
                  onClick={handleCreateBilling}
                  className={`w-1/2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400 flex items-center justify-center ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={loading}
                >
                  {loading ? <div className="h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div> : "Save"}
                </button>
                <button onClick={toggleModal} className="w-1/2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-400">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

    

export default Billings;