import React, { useEffect, useState, useRef } from 'react';
import Sidebar from '../Sidebar/sidebar';
import axios from 'axios';
import { Chart, LineElement, BarElement, CategoryScale, LinearScale, PointElement, LineController, BarController, Filler, Tooltip, Legend } from 'chart.js';

const GymDashboard = () => {
  const newMembersChartRef = useRef(null);
  const revenueChartRef = useRef(null);
  const billingChartRef = useRef(null);

  const [totalMembers, setTotalMembers] = useState(0);
  const [femaleMembers, setFemaleMembers] = useState(0);
  const [maleMembers, setMaleMembers] = useState(0);
  const [totalProjectedRevenue, setTotalProjectedRevenue] = useState(0);
  const [totalGoals, setTotalGoals] = useState(0);
  const [completedGoals, setCompletedGoals] = useState(0);
  const [totalPackages, setTotalPackages] = useState(0);
  const [totalMonthlyCollectedRevenue, setTotalMonthlyCollectedRevenue] = useState(0);
  const [totalUncollectedBalance, setTotalUncollectedBalance] = useState(0);
  const [newMembersData, setNewMembersData] = useState([]);
  const [monthlyRevenueChartData, setMonthlyRevenueChartData] = useState([]);
  const [monthlyBillingData, setMonthlyBillingData] = useState([]); // NEW
  const [dailyCollectedRevenue, setDailyCollectedRevenue] = useState(0); // NEW
  const [membersWithPackage, setMembersWithPackage] = useState(0);
  const [monthlyChurnRate, setMonthlyChurnRate] = useState(0);
  const [monthlyRetentionRate, setMonthlyRetentionRate] = useState(0);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = localStorage.getItem('token');
        const API_URL = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${API_URL}dashboard/dashboard-overview/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        const data = response.data;

        setTotalMembers(data.members.total_members);
        setFemaleMembers(data.members.female_members);
        setMaleMembers(data.members.male_members);
        setTotalProjectedRevenue(data.total_projected_revenue);
        setTotalGoals(data.goals.total_goals);
        setCompletedGoals(data.goals.completed_goals);
        setTotalPackages(data.total_packages);
        setTotalMonthlyCollectedRevenue(data.total_monthly_collected_revenue);
        setTotalUncollectedBalance(data.total_monthly_outstanding_balance);
        setNewMembersData(data.new_members_data);
        setMonthlyRevenueChartData(data.monthly_revenue_chart_data);
        setMonthlyBillingData(data.monthly_billing_data); // NEW
        setDailyCollectedRevenue(data.daily_collected_revenue); // NEW
        setMembersWithPackage(data.members_with_package);
        setMonthlyChurnRate(data.monthly_churn_rate);
        setMonthlyRetentionRate(data.monthly_retention_rate);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
  }, []);

  useEffect(() => {
    if (monthlyRevenueChartData.length > 0) {
      const labels = monthlyRevenueChartData.map(entry => `Day ${entry.day}`);
      const dataPoints = monthlyRevenueChartData.map(entry => entry.revenue);

      Chart.register(LineElement, CategoryScale, LinearScale, PointElement, LineController, Filler, Tooltip, Legend);

      if (revenueChartRef.current) {
        revenueChartRef.current.destroy();
      }

      const ctx = document.getElementById('monthlyRevenueChart').getContext('2d');
      revenueChartRef.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels,
          datasets: [{
            label: 'Monthly Revenue Collected (Ksh)',
            data: dataPoints,
            borderColor: 'rgba(173, 216, 230, 1)',
            backgroundColor: 'rgba(173, 216, 230, 0.2)',
            borderWidth: 2,
            fill: 'origin',
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: { display: true, text: 'Revenue (Ksh)' },
            },
            x: {
              title: { display: true, text: 'Days of the Month' },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: context => `Ksh ${context.raw}`,
              },
            },
          },
        },
      });
    }
  }, [monthlyRevenueChartData]);

  useEffect(() => {
    if (monthlyBillingData.length > 0) {
      const labels = monthlyBillingData.map(entry => entry.month);
      const dataPoints = monthlyBillingData.map(entry => entry.total_collected);

      Chart.register(BarElement, BarController, CategoryScale, LinearScale);

      if (billingChartRef.current) {
        billingChartRef.current.destroy();
      }

      const ctx = document.getElementById('monthlyBillingChart').getContext('2d');
      billingChartRef.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels,
          datasets: [{
            label: 'Monthly Total Collected (Ksh)',
            data: dataPoints,
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: { display: true, text: 'Revenue (Ksh)' },
            },
            x: {
              title: { display: true, text: 'Months' },
            },
          },
        },
      });
    }
  }, [monthlyBillingData]);

  useEffect(() => {
    if (newMembersData.length > 0) {
      const labels = newMembersData.map(entry => entry.month);
      const dataPoints = newMembersData.map(entry => entry.new_members);

      Chart.register(LineElement, CategoryScale, LinearScale, PointElement, LineController, Filler);

      if (newMembersChartRef.current) {
        newMembersChartRef.current.destroy();
      }

      const ctx = document.getElementById('newMembersChart').getContext('2d');
      newMembersChartRef.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels,
          datasets: [{
            label: 'New Members Added',
            data: dataPoints,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderWidth: 2,
            fill: 'origin',
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: { beginAtZero: true },
          },
        },
      });
    }
  }, [newMembersData]);

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow p-6">
        <div className="mb-6">
          <h1 className="text-2xl font-bold">Dashboard Overview</h1>
          <div className="bg-white p-4 rounded-lg shadow mt-4">
            <h2 className="font-bold text-xl">Statistics</h2>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="bg-gray-100 p-4 rounded-lg"><h3 className="font-bold">Total Members</h3><p className="text-2xl">{totalMembers}</p></div>
              <div className="bg-gray-100 p-4 rounded-lg"><h3 className="font-bold">Female Members</h3><p className="text-2xl">{femaleMembers}</p></div>
              <div className="bg-gray-100 p-4 rounded-lg"><h3 className="font-bold">Male Members</h3><p className="text-2xl">{maleMembers}</p></div>
              <div className="bg-gray-100 p-4 rounded-lg"><h3 className="font-bold">Members with Package</h3><p className="text-2xl">{membersWithPackage}</p></div>
              <div className="bg-gray-100 p-4 rounded-lg"><h3 className="font-bold">Monthly Churn Rate</h3><p className="text-2xl">{monthlyChurnRate}%</p></div>
              <div className="bg-gray-100 p-4 rounded-lg"><h3 className="font-bold">Monthly Retention Rate</h3><p className="text-2xl">{monthlyRetentionRate}%</p></div>
              <div className="bg-gray-100 p-4 rounded-lg"><h3 className="font-bold">Total Monthly Expected Revenue</h3><p className="text-2xl">Ksh {totalProjectedRevenue}</p></div>
              <div className="bg-gray-100 p-4 rounded-lg"><h3 className="font-bold">Total Monthly Collected Revenue</h3><p className="text-2xl">Ksh {totalMonthlyCollectedRevenue}</p></div>
              <div className="bg-gray-100 p-4 rounded-lg"><h3 className="font-bold">Total Uncollected Balance</h3><p className="text-2xl">Ksh {totalUncollectedBalance}</p></div>
              <div className="bg-gray-100 p-4 rounded-lg"><h3 className="font-bold">Total Goals</h3><p className="text-2xl">{totalGoals}</p></div>
              <div className="bg-gray-100 p-4 rounded-lg"><h3 className="font-bold">Completed Goals</h3><p className="text-2xl">{completedGoals}</p></div>
              <div className="bg-gray-100 p-4 rounded-lg"><h3 className="font-bold">Total Packages</h3><p className="text-2xl">{totalPackages}</p></div>
              <div className="bg-gray-100 p-4 rounded-lg"><h3 className="font-bold">Daily Collected Revenue</h3><p className="text-2xl">Ksh {dailyCollectedRevenue}</p></div> 
            </div>
          </div>

          <div className="bg-white p-4 rounded-lg shadow mt-6">
            <h2 className="font-bold text-xl">Monthly Revenue Collected</h2>
            <div style={{ height: '400px' }}>
              <canvas id="monthlyRevenueChart" className="mt-4"></canvas>
            </div>
          </div>

          <div className="bg-white p-4 rounded-lg shadow mt-6">
            <h2 className="font-bold text-xl">New Members Added</h2>
            <div style={{ height: '400px' }}>
              <canvas id="newMembersChart" className="mt-4"></canvas>
            </div>
          </div>

          <div className="bg-white p-4 rounded-lg shadow mt-6">
            <h2 className="font-bold text-xl">Month to Month Billing Overview</h2>
            <div style={{ height: '400px' }}>
              <canvas id="monthlyBillingChart" className="mt-4"></canvas>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default GymDashboard;