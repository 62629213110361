import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../Routes/AuthContext';
import { Dumbbell } from 'lucide-react';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [gymLogo, setGymLogo] = useState(null); // 🔹 Store gym logo
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const API_URL = process.env.REACT_APP_API_URL;

  // 🔹 Fetch Gym Logo for Branding
  useEffect(() => {
    const fetchGymDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}gyms/detail/`);
        if (response.data.length > 0) {
          setGymLogo(response.data[0].profile_gym_image_path);
        }
      } catch (err) {
        console.error('Error fetching gym details:', err);
      }
    };
    fetchGymDetails();
  }, [API_URL]);

  // 🔹 Redirect if Already Logged In
  useEffect(() => {
    const token = localStorage.getItem('token');
    const has_gym = localStorage.getItem('has_gym');
    const gyms = JSON.parse(localStorage.getItem('gyms') || '[]');

    if (token) {
      if (has_gym === 'true') navigate('/home');
      else if (has_gym === 'false') navigate('/create-gym');
      if (gyms.length > 1 && location.pathname !== '/gym-selection') navigate('/gym-selection');
      if (gyms.length === 1) {
        const selectedGym = gyms[0];
        localStorage.setItem('selected_gym', JSON.stringify(selectedGym));
        navigate(`/homepage/${selectedGym.gym_id}`);
      }
    }
  }, [navigate, location]);

  // 🔹 Form Submission Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_URL}users/auth/login/`, { email, password });

      login(response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      localStorage.setItem('profile', JSON.stringify(response.data.profile));

      // ✅ Ensure permissions are always stored
      if (response.data.permissions) {
        localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
      }

      if (response.data.is_sales_person) {
        localStorage.setItem('is_sales_person', true);
        navigate('/sales-dashboard');
        return;
      }
      if (response.data.has_gym !== undefined) {
        localStorage.setItem('has_gym', response.data.has_gym);
        navigate(response.data.has_gym ? '/home' : '/create-gym');
        return;
      }
      if (response.data.gyms) {
        localStorage.setItem('gyms', JSON.stringify(response.data.gyms));
        if (response.data.gyms.length === 1) {
          const selectedGym = response.data.gyms[0];
          localStorage.setItem('selected_gym', JSON.stringify(selectedGym));
          navigate(`/homepage/${selectedGym.gym_id}`, { state: { gym: selectedGym } });
        } else {
          navigate('/gym-selection');
        }
      }
      if (response.data.is_gym_staff) {
        localStorage.setItem('is_gym_staff', true);
        localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
        navigate('/home');
        return;
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Invalid credentials');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-100 to-gray-200 p-4">
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6">
          {/* 🔹 Show Gym Logo if Available */}
          <div className="flex flex-col items-center mb-6">
            {gymLogo ? (
              <img src={gymLogo} alt="Gym Logo" className="w-16 h-16 rounded-full mb-4" />
            ) : (
              <div className="h-12 w-12 bg-black rounded-xl flex items-center justify-center mb-4">
                <Dumbbell className="h-6 w-6 text-white" />
              </div>
            )}
            <h2 className="text-2xl font-bold">Welcome to GymStay</h2>
          </div>

          {error && <p className="text-red-500 text-center mb-4">{error}</p>}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="mt-1 block w-full p-2 border rounded-md focus:ring focus:ring-gray-200"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div>
              <div className="flex justify-between items-center">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
              </div>
              <input
                type="password"
                id="password"
                className="mt-1 block w-full p-2 border rounded-md focus:ring focus:ring-gray-200"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <Link to="/forgot-password" className="text-sm text-blue-500 hover:underline">
              Forgot password?
            </Link>
            <button
              type="submit"
              className={`w-full bg-black text-white p-2 rounded-md ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isLoading}
            >
              {isLoading ? 'Signing in...' : 'Sign in'}
            </button>
          </form>
          <p className="mt-4 text-center text-sm text-gray-500">
            Don't have an account?{' '}
            <Link to="/register" className="text-blue-500 hover:underline">
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;