import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";

const StaffDetail = () => {
  const { staffId } = useParams(); // Get staff ID from URL
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const [staff, setStaff] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedStaff, setUpdatedStaff] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [permissionsSaved, setPermissionsSaved] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [permissions, setPermissions] = useState({
    members: false,
    attendance: false,
    billings: false,
    classes: false,
    workouts: false,
    workoutTemplates: false,
    dashboard: false,
  });

  const hasPermissions = Object.values(permissions).some((val) => val);

  useEffect(() => {
    fetchStaffDetails(); 
  }, []);

  const fetchStaffDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}users/gym-staff/${staffId}/`, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      const { first_name, last_name, email, phone_number, permissions } = response.data;

      setStaff(response.data);
      setUpdatedStaff({ first_name, last_name, phone_number });

      // If permissions are returned, update state; otherwise, fetch them separately
      if (permissions) {
        setPermissions(permissions);
      } else {
        fetchStaffPermissions();
      }
    } catch (err) {
      console.error("Error fetching staff details:", err);
      setError("Failed to load staff details.");
    }
  };

  const fetchStaffPermissions = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}users/gym-staff/${staffId}/permissions/`, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      setPermissions(response.data);
    } catch (err) {
      console.error("Error fetching staff permissions:", err);
      setError("Failed to load staff permissions.");
    }
  };

  const handleUpdatePermissions = async () => {
    setIsLoading(true);
    
    try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${API_URL}users/gym-staff/${staffId}/permissions/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`
            },
            body: JSON.stringify({ ...permissions }),
        });

        if (response.ok) {
            setPermissionsSaved(true);
            setTimeout(() => setPermissionsSaved(false), 3000);  // Reset after 3 sec
        } else {
            alert("Failed to save permissions.");
            }
        } catch (error) {
            console.error("Error saving permissions:", error);
            alert("An error occurred.");
        } finally {
            setIsLoading(false);
        }
  };

  const handleInputChange = (e) => {
    setUpdatedStaff({ ...updatedStaff, [e.target.id]: e.target.value });
  };

  const handlePermissionChange = (e) => {
    setPermissions((prevPermissions) => {
        const updatedPermissions = { ...prevPermissions, [e.target.name]: e.target.checked };
        return updatedPermissions;
    });
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      await axios.put(`${API_URL}users/gym-staff/${staffId}/`, updatedStaff, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      setIsEditing(false);
      fetchStaffDetails(); // Refresh data
    } catch (err) {
      console.error("Error updating staff details:", err);
      setError("Failed to update staff details.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeactivate = async () => {
    setShowModal(false); // Close modal
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${API_URL}users/gym-staff/${staffId}/`, {
        headers: { Authorization: `Token ${token}` },
      });

      navigate("/staff"); // Redirect to staff list
    } catch (err) {
      console.error("Error deactivating staff:", err);
      setError("Failed to deactivate staff.");
    }
    
  };

  return (
    <div className="flex">
      <Sidebar />

      <div className="flex-1 p-6 bg-gray-100 min-h-screen">
        <h2 className="text-2xl font-bold mb-4">Staff Details</h2>

        {error && <p className="text-red-500">{error}</p>}

        {staff ? (
          <div className="bg-white p-6 rounded-lg shadow-md">
            {!isEditing ? (
              <>
                <p><strong>Name:</strong> {staff.first_name} {staff.last_name}</p>
                <p><strong>Email:</strong> {staff.email}</p>
                <p><strong>Phone:</strong> {staff.phone_number}</p>
                <p><strong>Status:</strong> 
                  {staff.is_active ? 
                    <span className="bg-green-100 text-green-800 px-2 py-1 rounded ml-2">Active</span> 
                    : 
                    <span className="bg-red-100 text-red-800 px-2 py-1 rounded ml-2">Inactive</span>
                  }
                </p>

                {/* Display Assigned Permissions */}
                
                <div className="mt-4">
                <h3 className="font-semibold mb-2">Assigned Permissions:</h3>
                
                {Object.entries(permissions).filter(([key, value]) => value && key !== "id" && key !== "staff").length === 0 ? (
                     <p className="text-red-500">No assigned permissions.</p>
                     ) : (
                      <ul className="list-disc pl-6">
                    {Object.entries(permissions)
                      .filter(([key, value]) => value && key !== "id" && key !== "staff") // Exclude unwanted keys
                      .map(([key]) => (
                        <li key={key} className="text-gray-700">{key.replace(/_/g, " ")}</li>
                      ))
                    }
                  </ul>
                  )}
                </div>

                {/* Buttons */}
                <div className="mt-4">
                  <button onClick={() => setIsEditing(true)} className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-400">
                    Edit
                  </button>
                  <button onClick={() => setShowModal(true)} className={`px-4 py-2 rounded ${staff.is_active ? "bg-red-500 hover:bg-red-400" : "bg-green-500 hover:bg-green-400"} text-white`}>
                    {staff.is_active ? "Deactivate" : "Activate"}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-gray-700">First Name</label>
                    <input type="text" id="first_name" value={updatedStaff.first_name} onChange={handleInputChange} className="w-full p-2 border rounded" />
                  </div>

                  <div>
                    <label className="block text-gray-700">Last Name</label>
                    <input type="text" id="last_name" value={updatedStaff.last_name} onChange={handleInputChange} className="w-full p-2 border rounded" />
                  </div>

                  <div>
                    <label className="block text-gray-700">Email</label>
                    <input type="email" id="email" value={updatedStaff.email} disabled className="w-full p-2 border rounded bg-gray-200" />
                  </div>

                  <div>
                    <label className="block text-gray-700">Phone Number</label>
                    <input type="text" id="phone_number" value={updatedStaff.phone_number} onChange={handleInputChange} className="w-full p-2 border rounded" />
                  </div>
                </div>
                
                {/* Permissions Checkbox Section */}
                <div className="mt-6 p-4 border rounded bg-gray-50">
                  <h3 className="text-lg font-semibold mb-2">Set Staff Permissions:</h3>
                  <div className="grid grid-cols-2 gap-4">
                    {Object.entries(permissions).map(([key, value]) => (
                      <div key={key} className="flex items-center">
                        <input type="checkbox" id={key} name={key} checked={value} onChange={handlePermissionChange} className="mr-2" />
                        <label htmlFor={key} className="text-gray-700 capitalize">{key.replace(/_/g, " ")}</label>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-end">
                  <button 
                      onClick={handleUpdatePermissions} 
                      className={`px-4 py-2 rounded transition-all duration-300 ${
                        permissionsSaved ? "bg-green-500 text-white" : "bg-blue-500 text-white"
                      }`}
                    >
                      {permissionsSaved ? "✅ Saved" : isLoading ? "Updating..." : "Add new Permissions"}
                    </button>
                    </div>
                </div>

                <div className="mt-4">
                  <button onClick={handleUpdate} className="bg-green-500 text-white px-4 py-2 rounded mr-2 hover:bg-green-400" disabled={isLoading}>
                    {isLoading ? "Updating..." : "Save Changes"}
                  </button>
                  <button onClick={() => setIsEditing(false)} className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-300">
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        ) : <p>Loading staff details...</p>}

        {/* Confirmation Modal */}
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg text-center">
              <p className="text-lg font-semibold mb-4">
                Are you sure you want to {staff?.is_active ? "deactivate" : "activate"} this staff member?
              </p>
              <div className="flex justify-center space-x-4">
                <button onClick={handleDeactivate} className={`px-4 py-2 rounded ${staff?.is_active ? "bg-red-500 hover:bg-red-400" : "bg-green-500 hover:bg-green-400"} text-white`}>
                  {staff?.is_active ? "Deactivate" : "Activate"}
                </button>
                <button onClick={() => setShowModal(false)} className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-300">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
);
};

export default StaffDetail;