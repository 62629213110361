import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Sidebar = () => {
  const [gymDetails, setGymDetails] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate(); 
  const token = localStorage.getItem('token');

  // Retrieve permissions
  const user = JSON.parse(localStorage.getItem('user') || '{}'); // Parse user data
  const isGymStaff = user.is_staff || false; // Extract is_staff from user data
  const staffPermissions = JSON.parse(localStorage.getItem('permissions') || '{}');

  useEffect(() => {
    const fetchGymDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}gyms/detail/`, {
          headers: { Authorization: `Token ${token}` },
        });
        if (response.data.length > 0) {
          setGymDetails(response.data[0]);
        }
      } catch (err) {
        console.error('Error fetching gym details:', err);
      }
    };
    fetchGymDetails();
  }, [API_URL, token]);

  const handleLogout = async () => {
    const confirmLogout = window.confirm('Are you sure you want to log out?');
    if (confirmLogout) {
      try {
        await axios.post(`${API_URL}users/auth/logout/`, {}, {
          headers: { Authorization: `Token ${token}` },
        });
        localStorage.clear();  // Clear all stored data on logout
        navigate('/login');
      } catch (err) {
        console.error('Error logging out:', err);
        alert('An error occurred while logging out.');
      }
    }
  };

  return (
    <aside className="bg-white w-64 p-4 border-r overflow-y-auto">
      <div className="flex items-center justify-between mb-6">
        <span className="font-bold text-xl ml-6">
          {gymDetails ? gymDetails.gym_name : 'Gymname'}
        </span>
        {gymDetails?.profile_gym_image_path ? (
          <img src={gymDetails.profile_gym_image_path} alt="Gym Profile" className="w-10 h-10 rounded-full ml-4" />
        ) : (
          <i className="iconoir-user text-3xl ml-4"></i>
        )}
      </div>

      <ul>
        {/* Show Dashboard only if allowed */}
        {(!isGymStaff || staffPermissions.dashboard_access) && (
          <li className="mb-4">
            <Link to="/dashboard" className="flex items-center space-x-4 p-2 hover:bg-gray-200 rounded">
              <i className="iconoir-leaderboard text-2xl"></i>
              <span>Dashboard</span>
            </Link>
          </li>
        )}

        {/* Show Attendance only if allowed */}
        {(!isGymStaff || staffPermissions.attendance_access) && (
          <li className="mb-4">
            <Link to="/attendance" className="flex items-center space-x-4 p-2 hover:bg-gray-200 rounded">
              <i className="iconoir-check-circle text-2xl"></i>
              <span>Attendance</span>
            </Link>
          </li>
        )}

        {/* Show Members only if allowed */}
        {(!isGymStaff || staffPermissions.members_access) && (
          <li className="mb-4">
            <Link to="/members" className="flex items-center space-x-4 p-2 hover:bg-gray-200 rounded">
              <i className="iconoir-group text-2xl"></i>
              <span>Members</span>
            </Link>
          </li>
        )}

        {/* Show Classes only if allowed */}
        {(!isGymStaff || staffPermissions.classes_access) && (
          <li className="mb-4">
            <Link to="/classes" className="flex items-center space-x-4 p-2 hover:bg-gray-200 rounded">
              <i className="iconoir-calendar text-2xl"></i>
              <span>Classes</span>
            </Link>
          </li>
        )}

        {/* Show Workouts only if allowed */}
        {(!isGymStaff || staffPermissions.workouts_access) && (
          <li className="mb-4">
            <Link to="/workouts" className="flex items-center space-x-4 p-2 hover:bg-gray-200 rounded">
              <i className="iconoir-gym text-2xl"></i>
              <span>Workouts</span>
            </Link>
          </li>
        )}

        {/* Show Workout Templates only if allowed */}
        {(!isGymStaff || staffPermissions.workout_templates_access) && (
          <li className="mb-4">
            <Link to="/workout-templates" className="flex items-center space-x-4 p-2 hover:bg-gray-200 rounded">
              <i className="iconoir-hourglass text-2xl"></i>
              <span>Workout Templates</span>
            </Link>
          </li>
        )}

        {/* Show Billings only if allowed */}
        {(!isGymStaff || staffPermissions.billings_access) && (
          <li className="mb-4">
            <Link to="/billings" className="flex items-center space-x-4 p-2 hover:bg-gray-200 rounded">
              <i className="iconoir-credit-card-solid text-2xl"></i>
              <span>Billings</span>
            </Link>
          </li>
        )}
        {/* Show Packages only for Gym Owners */}
        {(!isGymStaff || staffPermissions.package_access) && (
          <li className="mb-4">
            <Link to="/packages" className="flex items-center space-x-4 p-2 hover:bg-gray-200 rounded">
              <i className="iconoir-box text-2xl"></i>
              <span>Packages</span>
            </Link>
          </li>
        )}

        {/* Show Staff management only for Gym Owners */}
        {!isGymStaff && (
          <li className="mb-4">
            <Link to="/staff" className="flex items-center space-x-4 p-2 hover:bg-gray-200 rounded">
              <i className="iconoir-user text-2xl"></i>
              <span>Gym Staff</span>
            </Link>
          </li>
        )}

        {/* Logout Button */}
        <li className="mb-4">
          <button onClick={handleLogout} className="flex items-center space-x-4 p-2 hover:bg-gray-200 rounded w-full">
            <i className="iconoir-log-out text-2xl"></i>
            <span>Logout</span>
          </button>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;