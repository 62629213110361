import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/sidebar';
import { Link } from 'react-router-dom';

const Staff = () => {
  const [staff, setStaff] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [inviteError, setInviteError] = useState('');
  const [newStaff, setNewStaff] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    gym_id: '',
    role: ''
  });

  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch Gym Staff
  useEffect(() => {
    fetchStaff();
  }, []);

// Fetch Gym Details and Assign gym_id
    useEffect(() => {
        const fetchGymDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}gyms/detail/`, {
            headers: { Authorization: `Token ${token}` },
            });

            setNewStaff((prevState) => ({
            ...prevState,
            gym_id: response.data[0].id, // ✅ Automatically assign gym_id
            }));
        } catch (err) {
            console.error('Error fetching gym details:', err);
        }
        };

        fetchGymDetails();
    }, [API_URL]);

  const fetchStaff = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}users/gym-staff/`, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setStaff(response.data);
    } catch (err) {
      console.error('Error fetching gym staff:', err);
    }
  };

  // Search Staff
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setNewStaff({ ...newStaff, email });
  
    // Regex to validate email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const filteredStaff = staff.filter((s) =>
    s.first_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle Input Change
  const handleInputChange = (e) => {
    setNewStaff({ ...newStaff, [e.target.id]: e.target.value });
  };

  // Invite Staff API Call
  const handleInvite = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setInviteError('');
  
    try {
      const token = localStorage.getItem('token');
  
      const response = await axios.post(
        `${API_URL}users/gym-staff/invite/`,
        newStaff,
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      console.log(response.data); // Debugging to see the response
      setShowInviteForm(false);
      await fetchStaff(); // Refresh the staff list after invite
    } catch (err) {
      console.error('Error inviting new staff:', err);
      setInviteError(err.response?.data?.message || 'An error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">Gym Staff</h1>
          <button
            onClick={() => setShowInviteForm(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
          >
            Invite Staff
          </button>
        </div>

        {/* Search Input */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search for staff..."
            className="w-full p-2 border rounded"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>

        {/* Staff Table */}
        <div className="bg-white p-4 rounded shadow">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-150">
                <th className="py-2 px-4 text-left font-semibold">Staff Name</th>
                <th className="py-2 px-4 text-left font-semibold">Phone</th>
                <th className="py-2 px-4 text-left font-semibold">Role</th> 
                <th className="py-2 px-4 text-left font-semibold">Created At</th>
                <th className="py-2 px-4 text-left font-semibold">Verified</th>
                <th className="py-2 px-4 text-left font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredStaff.map((s) => (
                <tr key={s.id} className="border-b hover:bg-gray-100">
                  <td className="py-2 px-4">{s.first_name} {s.last_name}</td>
                  <td className="py-2 px-4">{s.phone_number}</td>
                  <td className="py-2 px-4">{s.role || 'N/A'}</td> 
                  <td className="py-2 px-4">{new Date(s.created_at).toLocaleDateString()}</td>
                  <td className="py-2 px-4">
                    {s.email_verified ? (
                      <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded">Verified</span>
                    ) : (
                      <span className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded">Not Verified</span>
                    )}
                  </td>
                  <td className="py-2 px-4">
                    <Link to={`/gym-staff/${s.id}`} className="text-blue-500 hover:underline">View</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Invite Staff Modal */}
        {showInviteForm && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
              <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                <h3 className="text-lg font-medium text-gray-900">Invite New Staff</h3>

                <form onSubmit={handleInvite} className="mt-4">
                  <div className="mb-4">
                    <label className="block font-bold text-gray-700">First Name</label>
                    <input
                      type="text"
                      id="first_name"
                      className="w-full p-2 border rounded"
                      value={newStaff.first_name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block font-bold text-gray-700">Last Name</label>
                    <input
                      type="text"
                      id="last_name"
                      className="w-full p-2 border rounded"
                      value={newStaff.last_name}
                      onChange={handleInputChange}
                    />
                  <div/>
                  <div className="mb-4">
                    <label className="block font-bold text-gray-700">Role</label> 
                    <select
                      id="role"
                      className="w-full p-2 border rounded"
                      value={newStaff.role}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled selected>Select a role</option>
                      <option value="Receptionist">Receptionist</option>
                      <option value="Trainer">Trainer</option>
                      <option value="Manager">Manager</option>
                      <option value="Coach">Coach</option>
                      <option value="Sales">Sales</option>
                    </select>
                  </div>
                  </div>
                  <div className="mb-4">
                    <label className="block font-bold text-gray-700">Email</label>
                    <input
                      type="email"
                      id="email"
                      className="w-full p-2 border rounded"
                      value={newStaff.email}
                      onChange={handleEmailChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block font-bold text-gray-700">Phone Number</label>
                    <input
                      type="text"
                      id="phone_number"
                      className="w-full p-2 border rounded"
                      value={newStaff.phone_number}
                      onChange={handleInputChange}
                    />
                  </div>

                  {inviteError && <p className="text-red-600 text-sm">{inviteError}</p>}

                  <div className="flex justify-end space-x-2">
                    <button type="button" onClick={() => setShowInviteForm(false)} className="bg-gray-300 px-4 py-2 rounded">
                      Cancel
                    </button>
                    <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded">
                      {isLoading ? 'Sending...' : 'Send Invite'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Staff;