import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/sidebar';
import { useNavigate } from 'react-router-dom';  

const Packages = () => {
  const [packages, setPackages] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [newPackage, setNewPackage] = useState({
    package_name: '',
    amount: '',
    package_description: '',
    duration: '',
    package_type: 'monthly',
  });
  const [showPackageForm, setShowPackageForm] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();  

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}packages/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setPackages(response.data);
      } catch (err) {
        console.error('Error fetching packages:', err);
      }
    };

    fetchPackages();
  }, [API_URL]);

  const handleInputChange = (e) => {
    setNewPackage({
      ...newPackage,
      [e.target.id]: e.target.value,
    });
    setFieldErrors({ ...fieldErrors, [e.target.id]: '' }); // clear individual error
  };

  const handleAddPackage = async (e) => {
    e.preventDefault();
    const { package_name, amount, package_description, duration, package_type } = newPackage;

    const errors = {};
    if (!package_name) errors.package_name = "Package name is required.";
    if (!amount) errors.amount = "Amount is required.";
    if (!package_description) errors.package_description = "Description is required.";
    if (!duration) errors.duration = "Duration is required.";
    if (!package_type) errors.package_type = "Package type is required.";

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}packages/`, newPackage, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setShowPackageForm(false);
      window.location.reload();
    } catch (err) {
      console.error('Error adding new package:', err);
    }
  };

  const handlePackageClick = (id) => {
    navigate(`/packages/${id}`);  
  };

  const durationLabel = () => {
    if (newPackage.package_type === 'daily') return 'Duration (days)';
    if (newPackage.package_type === 'weekly') return 'Duration (weeks)';
    return 'Duration (months)';
  };

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">Packages</h1>
          <button
            onClick={() => setShowPackageForm(true)}
            className="bg-black text-white p-2 rounded hover:bg-gray-800"
          >
            Add Package
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {packages.map((pkg) => (
            <div 
              key={pkg.id} 
              className="bg-white p-4 rounded-lg shadow cursor-pointer"
              onClick={() => handlePackageClick(pkg.id)} 
            >
              <h2 className="font-bold text-xl mb-2">{pkg.package_name}</h2>
              <p className="text-gray-600">{pkg.package_description || 'No description available'}</p>
              <p className="font-bold mt-2">Ksh {pkg.amount}</p>
              <p className="text-gray-500">Duration: {pkg.duration} {pkg.package_type}(s)</p>
            </div>
          ))}
        </div>

        {showPackageForm && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <div className="flex justify-between items-center">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Add New Package</h3>
                      <button
                        onClick={() => setShowPackageForm(false)}
                        className="text-gray-500 hover:text-gray-700"
                      >
                        <span className="sr-only">Close</span>
                        &times;
                      </button>
                    </div>
                    <div className="mt-2">
                      <form onSubmit={handleAddPackage}>
                        <div className="mb-4 text-left">
                          <label htmlFor="package_name" className="block text-gray-700 mb-2 font-semibold">Package Name</label>
                          <input
                            type="text"
                            id="package_name"
                            className="w-full p-2 border rounded"
                            placeholder="Enter package name"
                            value={newPackage.package_name}
                            onChange={handleInputChange}
                          />
                          {fieldErrors.package_name && <p className="text-red-500 text-sm mt-1">{fieldErrors.package_name}</p>}
                        </div>
                        <div className="mb-4 text-left">
                          <label htmlFor="amount" className="block text-gray-700 mb-2 font-semibold">Amount</label>
                          <input
                            type="text"
                            id="amount"
                            className="w-full p-2 border rounded"
                            placeholder="Enter amount"
                            value={newPackage.amount}
                            onChange={handleInputChange}
                          />
                          {fieldErrors.amount && <p className="text-red-500 text-sm mt-1">{fieldErrors.amount}</p>}
                        </div>
                        <div className="mb-4 text-left">
                          <label htmlFor="package_description" className="block text-gray-700 mb-2 font-semibold">Description</label>
                          <input
                            type="text"
                            id="package_description"
                            className="w-full p-2 border rounded"
                            placeholder="Enter description"
                            value={newPackage.package_description}
                            onChange={handleInputChange}
                          />
                          {fieldErrors.package_description && <p className="text-red-500 text-sm mt-1">{fieldErrors.package_description}</p>}
                        </div>
                        <div className="mb-4 text-left">
                          <label htmlFor="package_type" className="block text-gray-700 mb-2 font-semibold">Package Type</label>
                          <select
                            id="package_type"
                            className="w-full p-2 border rounded"
                            value={newPackage.package_type}
                            onChange={handleInputChange}
                          >
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                          </select>
                          {fieldErrors.package_type && <p className="text-red-500 text-sm mt-1">{fieldErrors.package_type}</p>}
                        </div>
                        <div className="mb-4 text-left">
                          <label htmlFor="duration" className="block text-gray-700 mb-2 font-semibold">{durationLabel()}</label>
                          <input
                            type="number"
                            id="duration"
                            className="w-full p-2 border rounded"
                            placeholder={`Enter duration in ${newPackage.package_type}s`}
                            value={newPackage.duration}
                            onChange={handleInputChange}
                          />
                          {fieldErrors.duration && <p className="text-red-500 text-sm mt-1">{fieldErrors.duration}</p>}
                        </div>
                        <div className="flex justify-between">
                          <button type="button" onClick={() => setShowPackageForm(false)} className="bg-gray-300 text-black p-2 rounded hover:bg-gray-400">
                            Cancel
                          </button>
                          <button type="submit" className="bg-black text-white p-2 rounded hover:bg-gray-800">
                            Add Package
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Packages;