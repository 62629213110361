import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Sidebar/sidebar";

const AttendanceList = () => {
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newKeyFob, setNewKeyFob] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // New State for Success Message
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchAttendanceRecords();
  }, []);

  const fetchAttendanceRecords = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}attendance/records/`, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      setAttendanceRecords(response.data);
    } catch (err) {
      console.error("Error fetching attendance records:", err);
    }
  };

  const handleCreateKeyFob = async () => {
    if (!newKeyFob) {
      setErrorMessage("Please provide a key fob number.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${API_URL}attendance/keyfobs/`,
        { keyfob_number: newKeyFob },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      fetchAttendanceRecords(); // Refresh records after creation
      setSuccessMessage("Key fob created successfully!"); // Set success message
      setTimeout(() => setSuccessMessage(""), 3000); // Auto-hide success message
      toggleModal();
    } catch (err) {
      if (err.response && err.response.status === 400 && err.response.data.error) {
        setErrorMessage(err.response.data.error);
      } else {
        setErrorMessage("Error creating key fob. Please try again.");
      }
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    setNewKeyFob("");
    setErrorMessage("");
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Attendance Records</h1>
          <button
            onClick={toggleModal}
            className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800 transition-all duration-300"
          >
            Create Key Fob
          </button>
        </div>

        {/* Success Message */}
        {successMessage && (
          <div className="mb-4 text-green-600 bg-green-100 p-3 rounded">
            {successMessage}
          </div>
        )}

        {/* Cards for today's attendance */}
        <div className="flex space-x-4 mb-6">
          <div className="p-4 bg-blue-100 rounded shadow text-center">
            <h2 className="text-lg font-semibold">Today's Attendance</h2>
            <p className="text-3xl font-bold">
              {attendanceRecords.filter((record) => {
                const today = new Date().toDateString();
                const checkinDate = new Date(record.checkin_time).toDateString();
                return today === checkinDate;
              }).length}
            </p>
          </div>
          <div className="p-4 bg-red-100 rounded shadow text-center">
            <h2 className="text-lg font-semibold">Unpaid Billing</h2>
            <p className="text-3xl font-bold">
              {attendanceRecords.filter((record) => record.billing_status === "Expired").length}
            </p>
          </div>
        </div>

        {/* Attendance Records Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-lg rounded-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Member Name</th>
                <th className="py-3 px-6 text-left">Check-in Time</th>
                <th className="py-3 px-6 text-left">Check-out Time</th>
                <th className="py-3 px-6 text-left">Status</th>
                <th className="py-3 px-6 text-left">Billing Status</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {attendanceRecords.map((record, index) => (
                <tr
                  key={index}
                  className="border-b border-gray-200 hover:bg-gray-100"
                >
                  <td className="py-3 px-6">{record.member_name}</td>
                  <td className="py-3 px-6">{formatDateTime(record.checkin_time)}</td>
                  <td className="py-3 px-6">
                    {record.checkout_time ? formatDateTime(record.checkout_time) : "N/A"}
                  </td>
                  <td className="py-3 px-6">{record.status}</td>
                  <td className="py-3 px-6">
                    <span
                      className={`py-1 px-3 rounded-full text-xs font-semibold ${
                        record.billing_status === "Expired"
                          ? "bg-red-200 text-red-600"
                          : "bg-green-200 text-green-600"
                      }`}
                    >
                      {record.billing_status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Modal for creating Key Fob */}
        {showModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-xl font-bold mb-4">Create Key Fob</h2>
              <div>
                <label className="block text-gray-700 mb-2">Key Fob Number</label>
                <input
                  type="text"
                  value={newKeyFob}
                  onChange={(e) => setNewKeyFob(e.target.value)}
                  placeholder="Enter key fob number"
                  className="w-full border border-gray-300 p-2 rounded mb-2"
                />
              </div>
              {errorMessage && (
                <div className="text-red-500 text-sm mt-2">{errorMessage}</div>
              )}
              <div className="flex justify-end mt-4">
                <button
                  onClick={toggleModal}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-400 mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleCreateKeyFob}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default AttendanceList;