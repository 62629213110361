import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const MemberAccountSuccess = () => {
  const { unique_string } = useParams();
  const [gymName, setGymName] = useState('');
  const [profilePhotoPath, setProfilePhotoPath] = useState('');
  const [loading, setLoading] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}users/invite-email/action/${unique_string}/`);
        setGymName(response.data.gym_name);
        setProfilePhotoPath(response.data.profile_photo_path);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [API_URL, unique_string]);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className="bg-gray-100 font-sans leading-normal tracking-normal h-screen flex items-center justify-center">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full mx-4">
        <h1 className="text-3xl font-bold mb-6 text-center">Member Sign Up Successful</h1>
        <div className="flex justify-center mb-4">
          <i className="iconoir-check-circle text-green-500 text-6xl"></i>
        </div>
        <div className="flex justify-center mb-4">
          {profilePhotoPath ? (
            <img
              src={profilePhotoPath}
              alt="Profile"
              className="w-24 h-24 rounded-full"
            />
          ) : (
            <i className="iconoir-user text-black text-6xl"></i>
          )}
        </div>
        <p className="text-center text-gray-700 mb-4">
          Welcome to {gymName}! You can now start your fitness journey. Soon you will be able to log in and view gym classes, your workouts, and more.
        </p>
      </div>
    </div>
  );
};

export default MemberAccountSuccess;