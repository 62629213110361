import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

const MemberGoal = ({ memberId, gymId }) => {
  const [goals, setGoals] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newGoalData, setNewGoalData] = useState({
    goal_name: '',
    goal_type: '',
    goal_description: '',
    goal_deadline_in_days: '',
    goal_status: 'In Progress',
    current_goal_image_name: null,
  });

  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch goals from the backend
  const fetchGoals = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}goals/${memberId}/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setGoals(response.data);
    } catch (error) {
      console.error('Error fetching goals:', error);
    }
  };

  useEffect(() => {
    fetchGoals();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGoalData({ ...newGoalData, [name]: value });
  };

  const handleFileChange = (e) => {
    setNewGoalData({ ...newGoalData, current_goal_image_name: e.target.files[0] });
  };

  const handleSaveGoal = async () => {
    setIsSaving(true);

    const formData = new FormData();
    Object.entries(newGoalData).forEach(([key, value]) => {
      if (value) formData.append(key, value);
    });

    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}goals/${memberId}/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Goal added successfully!');
      setIsModalOpen(false);
      fetchGoals();
    } catch (error) {
      console.error('Error saving goal:', error);
      toast.error('Failed to save goal.');
    } finally {
      setIsSaving(false);
    }
  };

  // ✅ Format the deadline date to "Month Day, Year"
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const renderGoalCard = () => (
    goals.map((goal) => (
      <section key={goal.id} className="bg-white p-4 rounded-lg shadow flex mb-4">
        <img
          src={goal.current_goal_image_path || "https://placehold.co/150x100"}
          alt="Goal Image"
          className="rounded-lg mr-4 w-1/3"
        />
        <div className="flex-1">
          <h2 className="text-xl font-bold mb-2">Goal Details</h2>
          <p className="mb-2"><strong>Goal Name:</strong> {goal.goal_name}</p>
          <p className="mb-2"><strong>Goal Type:</strong> {goal.goal_type}</p>
          <p className="mb-2"><strong>Goal Description:</strong> {goal.goal_description}</p>
          <p className="mb-2">
            <strong>Goal Deadline:</strong> {goal.goal_deadline_date ? formatDate(goal.goal_deadline_date) : 'N/A'}
          </p>
          <p className="mb-2"><strong>Goal Status:</strong> <span className="text-green-500 font-bold">{goal.goal_status}</span></p>
        </div>
      </section>
    ))
  );

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      {goals && goals.length > 0 ? (
        renderGoalCard()
      ) : (
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
          onClick={() => setIsModalOpen(true)}
        >
          Add Goal
        </button>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Add New Goal</h2>

            <div className="mb-4">
              <label className="block mb-2">Goal Name</label>
              <input
                type="text"
                name="goal_name"
                value={newGoalData.goal_name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-2">Goal Type</label>
              <input
                type="text"
                name="goal_type"
                value={newGoalData.goal_type}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-2">Goal Description</label>
              <textarea
                name="goal_description"
                value={newGoalData.goal_description}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-2">Deadline (in days)</label>
              <input
                type="number"
                name="goal_deadline_in_days"
                value={newGoalData.goal_deadline_in_days}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-2">Current Before Image (optional) </label>
              <input
                type="file"
                name="current_goal_image_name"
                onChange={handleFileChange}
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-400 mr-2"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>

              <button
                className={`px-4 py-2 rounded text-white ${isSaving ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-400'}`}
                onClick={handleSaveGoal}
                disabled={isSaving}
              >
                {isSaving ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-2 text-white" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z" />
                    </svg>
                    Saving...
                  </div>
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberGoal;