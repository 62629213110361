// src/components/landing.js

import React, { useState } from "react"

// UI components from your ui folder
import { Button } from "../Ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "../Ui/card"
import { Badge } from "../Ui/badge"

// Icons from lucide-react
import {
  Menu,
  X,
  BarChart,
  Users,
  Package,
  CreditCard,
  Calendar,
  CheckCircle,
} from "lucide-react"

function LandingPage() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen)
  }

  return (
    <div className="flex min-h-screen w-full flex-col bg-background font-sans">
      {/* Header */}
      <header className="sticky top-0 z-40 w-full border-b bg-background/95 backdrop-blur">
        <div className="container mx-auto px-4 flex h-16 items-center justify-between">
          {/* Logo */}
          <a href="/" className="flex items-center gap-2">
            <img
              src="/logo.jpeg"
              alt="GymStay Mosaic Logo"
              className="h-12 w-auto object-contain"
            />
            <span className="text-2xl font-bold tracking-tight">GymStay </span>
          </a>

          {/* Desktop Nav */}
          <nav className="hidden md:flex items-center gap-6">
            <a
              href="#features"
              className="text-sm font-medium transition-colors hover:text-primary"
            >
              Features
            </a>
            <a
              href="#pricing"
              className="text-sm font-medium transition-colors hover:text-primary"
            >
              Pricing
            </a>
            <a
              href="#contact"
              className="text-sm font-medium transition-colors hover:text-primary"
            >
              Contact
            </a>
            <a
              href="https://substack.com/@gymstay"
              className="text-sm font-medium transition-colors hover:text-primary"
            >
              blog
            </a>
          </nav>

          {/* Desktop Buttons */}
          <div className="hidden md:flex items-center gap-4">
            <a href="/login">
              <Button variant="ghost" size="sm" className="text-primary">
                Log in
              </Button>
            </a>
            <a href="/register">
              <Button size="sm" className="bg-primary text-white hover:bg-primary/90">
                Register
              </Button>
            </a>
          </div>

          {/* Mobile Menu Button */}
          <Button
            variant="ghost"
            size="icon"
            className="md:hidden text-primary"
            onClick={toggleMobileMenu}
            aria-label="Toggle menu"
          >
            {isMobileMenuOpen ? <X className="h-5 w-5" /> : <Menu className="h-5 w-5" />}
          </Button>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="container mx-auto px-4 md:hidden py-4 border-t">
            <nav className="flex flex-col space-y-4">
              <a
                href="#features"
                className="text-sm font-medium transition-colors hover:text-primary"
                onClick={() => setMobileMenuOpen(false)}
              >
                Features
              </a>
              <a
                href="#pricing"
                className="text-sm font-medium transition-colors hover:text-primary"
                onClick={() => setMobileMenuOpen(false)}
              >
                Pricing
              </a>
              <a
                href="#contact"
                className="text-sm font-medium transition-colors hover:text-primary"
                onClick={() => setMobileMenuOpen(false)}
              >
                Contact
              </a>
              <div className="flex flex-col gap-2 pt-2">
                <a href="/login" onClick={() => setMobileMenuOpen(false)}>
                  <Button
                    variant="outline"
                    className="w-full justify-center text-primary border-primary hover:bg-primary/10"
                  >
                    Log in
                  </Button>
                </a>
                <a href="/register" onClick={() => setMobileMenuOpen(false)}>
                  <Button className="w-full justify-center bg-primary text-white hover:bg-primary/90">
                    Register
                  </Button>
                </a>
              </div>
            </nav>
          </div>
        )}
      </header>

      <main className="flex-1">
        {/* Hero Section */}
        <section className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-primary/5 z-0" />
          <div className="container mx-auto px-4 relative z-10 py-24 md:py-32 flex flex-col items-center text-center">
            <Badge className="mb-6">For Fitness and wellness </Badge>
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight mb-6 max-w-3xl">
              Be In Control of Your Fitness Business
            </h1>
            <p className="text-lg md:text-xl text-muted-foreground mb-8 max-w-2xl">
            Our Mission is to arm fitness businesses with cutting-edge retention tools, analytics, and engagement features that keep members crushing goals and returning for more, all while your revenue keeps climbing
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              {/* “Get Started” -> same link as top nav "Register" */}
              <a href="/register">
                <Button
                  size="md"
                  className="bg-primary text-white hover:bg-primary/90"
                >
                  Get Started
                </Button>
              </a>

              {/* “Book a Demo” -> Calendly link in new tab */}
              <a
                href="https://calendly.com/machariaa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  size="md"
                  variant="outline"
                  className="border-primary text-primary hover:bg-primary/10"
                >
                  Book a Demo
                </Button>
              </a>
            </div>
          </div>
        </section>

        {/*
          ------------------------------------------------
          NEW SECTION: The Big Problem
          ------------------------------------------------
        */}
        <section className="py-16">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-6">
                The Big Problem
              </h2>
              <p className="text-lg text-muted-foreground mb-8">
                Fitness businesses often face annual churn rates of <strong>30%+</strong>. 
                In fact, up to <strong>67%</strong> of memberships are never renewed if 
                not proactively managed—leading to steep revenue losses.
              </p>
            </div>
            <div className="max-w-4xl mx-auto grid gap-6 md:grid-cols-3">
            <Card className="shadow-sm border-none">
                <CardHeader className="border-none">
                  <CardTitle className="text-lg font-semibold">
                  Paying More, Getting Less
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                  Many fitness businesses pay for expensive platforms that lack the robust
                  engagement tools needed to retain members while others remain stuck with
                  manual processes. Both lose revenue and miss out on real member insights.
                  </p>
                </CardContent>
              </Card>
              <Card className="shadow-sm border-none">
                <CardHeader className="border-none">
                  <CardTitle className="text-lg font-semibold">
                    No Follow-up
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                  Without timely payment reminders or check-ins on member progress, gyms miss
                  crucial touchpoints that build trust. This lack of consistent follow-up 
                  often results in overdue fees, unnoticed cancellations, and a loss of deeper 
                  engagement that keeps members invested in their fitness journey.
                  </p>
                </CardContent>
              </Card>
              <Card className="shadow-sm border-none">
                <CardHeader className="border-none">
                  <CardTitle className="text-lg font-semibold">
                    Disengaged Members
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                  When members’ goals aren’t tracked and celebrated, they quickly lose motivation 
                  and drift away. A lack of visibility into milestones and progress means higher 
                  churn, fewer referrals, and an overall struggle to foster a sense of community 
                  that drives long-term loyalty.
                  </p>
                </CardContent>
              </Card>
              
            </div>
          </div>
        </section>

        {/*
          ------------------------------------------------
          NEW SECTION: Why It Matters Now (Opportunity)
          ------------------------------------------------
        */}
        <section className="py-16 bg-muted/50">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center mb-8">
              <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-4">
                Why It Matters Now
              </h2>
              <p className="text-lg text-muted-foreground">
                Cutting churn by just <strong>5-10%</strong> can significantly 
                increase annual revenue. Better goal tracking can boost retention by 
                up to <strong>25%</strong>.
              </p>
            </div>

            <div className="max-w-4xl mx-auto flex flex-col md:flex-row gap-6">
              <div className="flex-1 p-6 rounded-lg bg-white shadow-sm">
                <h3 className="text-xl font-semibold mb-2">
                  The Cost of Doing Nothing
                </h3>
                <ul className="list-disc list-inside text-muted-foreground space-y-1">
                  <li>Wasted marketing investment reacquiring lost members</li>
                  <li>Inconsistent tracking leads to missed renewal reminders</li>
                  <li>Revenue leakage from uncollected payments</li>
                </ul>
              </div>

              <div className="flex-1 p-6 rounded-lg bg-white shadow-sm">
                <h3 className="text-xl font-semibold mb-2">
                  The Potential Upside
                </h3>
                <ul className="list-disc list-inside text-muted-foreground space-y-1">
                  <li>Proactively reduce churn and grow revenue</li>
                  <li>Engage members with tracked goals & progress</li>
                  <li>Gain clarity with streamlined, automated processes</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/*
          ------------------------------------------------
          NEW SECTION: Our Solution (GymStay at a Glance)
          ------------------------------------------------
        */}
        <section className="py-16">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center mb-12">
              <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-4">
                Our Solution: GymStay at a Glance
              </h2>
              <p className="text-lg text-muted-foreground">
                GymStay is an all-in-one platform that simplifies membership management,
                billing, goal tracking, and engagement—so you stop losing members to 
                manual slip-ups.
              </p>
            </div>

            <div className="max-w-4xl mx-auto grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              <Card className="border-none shadow-sm hover:shadow-md transition-all">
                <CardHeader className="border-none">
                  <CardTitle className="text-lg font-semibold">1. Smart Onboarding</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    Invite members digitally, eliminating paperwork and ensuring a
                    smooth start.
                  </p>
                </CardContent>
              </Card>

              <Card className="border-none shadow-sm hover:shadow-md transition-all">
                <CardHeader className="border-none">
                  <CardTitle className="text-lg font-semibold">2. Goals & Progress</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    Let gym owners set & track member goals, 
                    boosting engagement and accountability.
                  </p>
                </CardContent>
              </Card>

              <Card className="border-none shadow-sm hover:shadow-md transition-all">
                <CardHeader className="border-none">
                  <CardTitle className="text-lg font-semibold">
                    3. Custom Packages
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    Build flexible monthly, quarterly, or annual memberships to suit every client.
                  </p>
                </CardContent>
              </Card>

              <Card className="border-none shadow-sm hover:shadow-md transition-all">
                <CardHeader className="border-none">
                  <CardTitle className="text-lg font-semibold">
                    4. Automated Billing
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    No more missed payments. Automated receipts and reminders keep revenue flowing.
                  </p>
                </CardContent>
              </Card>

              <Card className="border-none shadow-sm hover:shadow-md transition-all">
                <CardHeader className="border-none">
                  <CardTitle className="text-lg font-semibold">
                    5. Insights Dashboard
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    Monitor churn rates, revenue, demographics, and more in one place.
                  </p>
                </CardContent>
              </Card>

              <Card className="border-none shadow-sm hover:shadow-md transition-all">
                <CardHeader className="border-none">
                  <CardTitle className="text-lg font-semibold">
                    6. Engagement Tools
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    Keep members motivated with progress updates, 
                    milestone check-ins.
                  </p>
                </CardContent>
              </Card>
            </div>

            {/* CTA beneath Our Solution */}
            <div className="text-center mt-10">
              <a href="/register">
                <Button className="bg-primary text-white hover:bg-primary/90">
                  Start Your Free Trial
                </Button>
              </a>
            </div>
          </div>
        </section>

        {/*
          ------------------------------------------------
          Existing "Who Is This For?" Section
          ------------------------------------------------
        */}
        <section id="for-who" className="py-20 bg-muted/50">
          <div className="container mx-auto px-4">
            <div className="flex flex-col items-center text-center mb-12">
              <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-4">Who Is This For?</h2>
              <p className="text-lg text-muted-foreground max-w-2xl">
                Our platform is designed to meet the needs of various fitness businesses.
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-6">
              <Card className="border-none shadow-md transition-all hover:shadow-lg">
                <CardHeader className="pb-2 border-none">
                  <CardTitle className="flex items-center gap-2">
                    <Users className="h-5 w-5 text-primary" />
                    Gym Owners
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    Streamline operations with ease. Manage members, track attendance,
                    and grow your business.
                  </p>
                </CardContent>
              </Card>

              <Card className="border-none shadow-md transition-all hover:shadow-lg">
                <CardHeader className="pb-2 border-none">
                  <CardTitle className="flex items-center gap-2">
                    <Calendar className="h-5 w-5 text-primary" />
                    Personal Trainers
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    Manage clients and sessions effortlessly. Schedule appointments
                    and track progress.
                  </p>
                </CardContent>
              </Card>

              <Card className="border-none shadow-md transition-all hover:shadow-lg">
                <CardHeader className="pb-2 border-none">
                  <CardTitle className="flex items-center gap-2">
                    <Package className="h-5 w-5 text-primary" />
                    Yoga Studios
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    Enjoy a hassle-free management solution. Organize classes
                    and build your community.
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>

        {/* Client Logos */}
        <section className="py-16 bg-background">
          <div className="container mx-auto px-4">
            <div className="flex flex-col items-center text-center mb-10">
              <h2 className="text-2xl md:text-3xl font-bold tracking-tight mb-4">
                Trusted by Gyms Everywhere
              </h2>
              <p className="text-muted-foreground max-w-2xl">
                Join the growing community of fitness businesses that rely on GymStay.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center justify-items-center">
              <div className="bg-muted/30 rounded-lg p-6 w-full max-w-xs h-32 flex items-center justify-center">
                <img
                  src="https://live-gym-crm-bucket.s3.us-west-2.amazonaws.com/images/gym_profile_images/WhatsApp+Image+2025-02-26+at+16.24.19.jpeg"
                  alt="Fitness Plus Gym"
                  className="max-h-16 object-contain"
                />
              </div>
              <div className="bg-muted/30 rounded-lg p-6 w-full max-w-xs h-32 flex items-center justify-center">
                <img
                  src="https://live-gym-crm-bucket.s3.amazonaws.com/images/gym_profile_images/Mofit_Tizika_Joska/c57b7de3-9cb4-4d55-a7fd-2fe794128da7.jpeg"
                  alt="PowerHouse Fitness"
                  className="max-h-16 object-contain"
                />
              </div>
              <div className="bg-muted/30 rounded-lg p-6 w-full max-w-xs h-32 flex items-center justify-center">
                <img
                  src="https://live-gym-crm-bucket.s3.amazonaws.com/images/gym_profile_images/POIXA_FLEX_GYM/5ad05df7-2d4b-4322-92c0-d260fbe00bd7.jpg"
                  alt="Elite Training Center"
                  className="max-h-16 object-contain"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Features */}
        <section id="features" className="py-20">
          <div className="container mx-auto px-4">
            <div className="flex flex-col items-center text-center mb-12">
              <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-4">
                Everything You Need to Manage Your Gym
              </h2>
              <p className="text-lg text-muted-foreground max-w-2xl">
                Powerful tools designed to streamline your fitness business operations.
              </p>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              <Card className="border-none shadow-sm hover:shadow-md transition-all">
                <CardHeader className="border-none">
                  <BarChart className="h-10 w-10 text-primary mb-2" />
                  <CardTitle className="text-xl font-semibold">Gym Analytics</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    Stay informed with real-time insights. Track member attendance,
                    revenue, and growth trends.
                  </p>
                </CardContent>
              </Card>

              <Card className="border-none shadow-sm hover:shadow-md transition-all">
                <CardHeader className="border-none">
                  <Users className="h-10 w-10 text-primary mb-2" />
                  <CardTitle className="text-xl font-semibold">Member Dashboard</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    View and track member activity effortlessly. Manage profiles
                    and monitor engagement.
                  </p>
                </CardContent>
              </Card>

              <Card className="border-none shadow-sm hover:shadow-md transition-all">
                <CardHeader className="border-none">
                  <Package className="h-10 w-10 text-primary mb-2" />
                  <CardTitle className="text-xl font-semibold">Package Management</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    Customize membership plans with ease. Create and manage
                    different tiers and offerings.
                  </p>
                </CardContent>
              </Card>

              <Card className="border-none shadow-sm hover:shadow-md transition-all">
                <CardHeader className="border-none">
                  <CreditCard className="h-10 w-10 text-primary mb-2" />
                  <CardTitle className="text-xl font-semibold">Billing Features</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    Automated invoicing and payment tracking. Simplify your
                    financial management.
                  </p>
                </CardContent>
              </Card>

              <Card className="border-none shadow-sm hover:shadow-md transition-all">
                <CardHeader className="border-none">
                  <Calendar className="h-10 w-10 text-primary mb-2" />
                  <CardTitle className="text-xl font-semibold">Class Management</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    Schedule and organize classes efficiently. Manage bookings and
                    instructor assignments.
                  </p>
                </CardContent>
              </Card>

              <Card className="border-none shadow-sm hover:shadow-md transition-all">
                <CardHeader className="border-none">
                  <CheckCircle className="h-10 w-10 text-primary mb-2" />
                  <CardTitle className="text-xl font-semibold">Attendance Tracking</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    Monitor member check-ins and class attendance. Generate reports
                    and identify patterns.
                  </p>
                </CardContent>
              </Card>

              <Card className="border-none shadow-sm hover:shadow-md transition-all">
                <CardHeader className="border-none">
                  <Users className="h-10 w-10 text-primary mb-2" />
                  <CardTitle className="text-xl font-semibold">Gym Staff Management</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground">
                    Manage what your staff gets access to, be it the manager,
                    receptionist, coach or gym sales.
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>

        {/* Pricing */}
        <section id="pricing" className="py-20 bg-muted/50">
          <div className="container mx-auto px-4">
            <div className="flex flex-col items-center text-center mb-12">
              <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-4">
                Choose the Right Plan
              </h2>
              <p className="text-lg text-muted-foreground max-w-2xl">
                Flexible pricing options to suit businesses of all sizes.
              </p>
            </div>

            {/* Now we have SIX cards instead of four */}
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">

              {/* 1) Starter (Free) */}
              <Card className="border-primary border-2 shadow-md transition-all hover:shadow-lg">
                <CardHeader className="text-center border-none">
                  <CardTitle className="text-2xl font-bold mb-2">Starter</CardTitle>
                </CardHeader>
                <CardContent className="text-center">
                  <div className="mb-4">
                    <span className="text-3xl font-bold">Free</span>
                  </div>
                  <p className="text-muted-foreground mb-6">Up to 10 members</p>
                  <ul className="space-y-2 mb-6 text-left">
                    <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Analytics</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>member management</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Billing automation</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Class scheduling</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Email support</span>
                    </li>
                  </ul>
                  <Button
                    variant="outline"
                    className="w-full border-primary text-primary hover:bg-primary/10"
                  >
                    Get Started
                  </Button>
                </CardContent>
              </Card>

              {/* 2) Launch (Ksh 5,000) */}
              <Card className="border-primary border-2 shadow-md transition-all hover:shadow-lg">
                <CardHeader className="text-center border-none">
                  <CardTitle className="text-2xl font-bold mb-2">Launch</CardTitle>
                </CardHeader>
                <CardContent className="text-center">
                  <div className="mb-4">
                    <span className="text-3xl font-bold">Ksh 5,000</span>
                    <span className="text-muted-foreground">/month</span>
                  </div>
                  <p className="text-muted-foreground mb-6">Up to 25 members</p>
                  <ul className="space-y-2 mb-6 text-left">
                    <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Staff account option (2K extra per account)</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Member app & login</span>
                    </li>
                    
                  </ul>
                  <Button
                    variant="outline"
                    className="w-full border-primary text-primary hover:bg-primary/10"
                  >
                    Get Started
                  </Button>
                </CardContent>
              </Card>

              {/* 3) Foundation (Ksh 10,000) */}
              <Card className="border-primary border-2 shadow-md transition-all hover:shadow-lg">
                <CardHeader className="text-center border-none">
                  <CardTitle className="text-2xl font-bold mb-2">Foundation</CardTitle>
                </CardHeader>
                <CardContent className="text-center">
                  <div className="mb-4">
                    <span className="text-3xl font-bold">Ksh 10,000</span>
                    <span className="text-muted-foreground">/month</span>
                  </div>
                  <p className="text-muted-foreground mb-6">Up to 100 members</p>
                  <ul className="space-y-2 mb-6 text-left">
                  <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Everything under launch</span>
                  </li>
                  <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Up to 100 members</span>
                  </li>
                    
                  </ul>
                  <Button
                    variant="outline"
                    className="w-full border-primary text-primary hover:bg-primary/10"
                  >
                    Get Started
                  </Button>
                </CardContent>
              </Card>

              {/* 4) Advanced (Ksh 17,000) */}
              <Card className="border-primary border-2 shadow-md transition-all hover:shadow-lg">
                <CardHeader className="text-center border-none">
                  <CardTitle className="text-2xl font-bold mb-2">Advanced</CardTitle>
                </CardHeader>
                <CardContent className="text-center">
                  <div className="mb-4">
                    <span className="text-3xl font-bold">Ksh 17,000</span>
                    <span className="text-muted-foreground">/month</span>
                  </div>
                  <p className="text-muted-foreground mb-6">Up to 125 members</p>
                  <ul className="space-y-2 mb-6 text-left">
                  <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Everything under Foundation</span>
                  </li>
                    <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Advanced analytics</span>
                    </li>
                    
                  </ul>
                  <Button
                    variant="outline"
                    className="w-full border-primary text-primary hover:bg-primary/10"
                  >
                    Get Started
                  </Button>
                </CardContent>
              </Card>

              {/* 5) Professional (Ksh 25,000) */}
              <Card className="border-primary border-2 shadow-md transition-all hover:shadow-lg">
                <CardHeader className="text-center border-none relative">
                  <Badge className="absolute right-4 top-4">Popular</Badge>
                  <CardTitle className="text-2xl font-bold mb-2">Professional</CardTitle>
                </CardHeader>
                <CardContent className="text-center">
                  <div className="mb-4">
                    <span className="text-3xl font-bold">Ksh 25,000</span>
                    <span className="text-muted-foreground">/month</span>
                  </div>
                  <p className="text-muted-foreground mb-6">Up to 200 members</p>
                  <ul className="space-y-2 mb-6 text-left">
                  <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Everything under advanced</span>
                  </li>
                    <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Full analytics suite</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Billing automation</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>24/7 support</span>
                    </li>
                  </ul>
                  <Button className="w-full bg-primary text-white hover:bg-primary/90">
                    Get Started
                  </Button>
                </CardContent>
              </Card>

              {/* 6) Enterprise (Custom) */}
              <Card className="border-primary border-2 shadow-md transition-all hover:shadow-lg">
                <CardHeader className="text-center border-none">
                  <CardTitle className="text-2xl font-bold mb-2">Enterprise</CardTitle>
                </CardHeader>
                <CardContent className="text-center">
                  <div className="mb-4">
                    <span className="text-3xl font-bold">Custom</span>
                  </div>
                  <p className="text-muted-foreground mb-6">Unlimited members</p>
                  <ul className="space-y-2 mb-6 text-left">
                    <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Custom integrations</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>Everything under Proffesional</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <CheckCircle className="h-4 w-4 text-primary" />
                      <span>SLA guarantees</span>
                    </li>
                  </ul>
                  <Button
                    variant="outline"
                    className="w-full border-primary text-primary hover:bg-primary/10"
                  >
                    Contact Sales
                  </Button>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>

        {/* Contact */}
        <section id="contact" className="py-20">
          <div className="container mx-auto px-4">
            <div className="flex flex-col items-center text-center mb-12">
              <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-4">
                Let's Get Started!
              </h2>
              <p className="text-lg text-muted-foreground max-w-2xl">
                Ready to transform your fitness business? Get in touch with us today.
              </p>
            </div>

            <div className="max-w-md mx-auto">
              <Card className="border-none shadow-lg">
                <CardContent className="pt-6">
                  <div className="space-y-4">
                    <div className="flex items-center gap-2 p-3 rounded-md bg-muted/50">
                      <span className="font-medium">Email:</span>
                      <a href="mailto:gymmosaic@gmail.com" className="text-primary hover:underline">
                        gymmosaic@gmail.com
                      </a>
                    </div>
                    <div className="flex items-center gap-2 p-3 rounded-md bg-muted/50">
                      <span className="font-medium">Phone:</span>
                      <a href="tel:+254795614942" className="text-primary hover:underline">
                        +254-795614942
                      </a>
                    </div>
                    <div className="flex items-center gap-2 p-3 rounded-md bg-muted/50">
                      <span className="font-medium">Instagram:</span>
                      <a href="https://instagram.com/gym_mossaic" className="text-primary hover:underline">
                        @gym_mossaic
                      </a>
                    </div>
                    {/* Same Calendly link for "Schedule a Call" */}
                    <a
                      href="https://calendly.com/machariaa"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block"
                    >
                      <Button className="w-full mt-4 bg-primary text-white hover:bg-primary/90">
                        Schedule a Call
                      </Button>
                    </a>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>
      </main>

      <footer className="border-t py-6 md:py-8">
        <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="flex items-center gap-2">
            <img
              src="/logo.jpeg"
              alt="GymStay Mosaic Logo"
              className="h-8 w-auto object-contain"
            />
            <span className="text-sm font-semibold">GymStay</span>
          </div>

          <div className="text-sm text-muted-foreground">
            © {new Date().getFullYear()} GymStay. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  )
}

export default LandingPage