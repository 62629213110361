import React from 'react';
import { useNavigate } from 'react-router-dom';

const StaffAccountSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-100 h-screen flex items-center justify-center">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
        <h1 className="text-3xl font-bold mb-6 text-center">Account Setup Successful</h1>
        <div className="flex justify-center mb-4">
          <i className="iconoir-check-circle text-green-500 text-6xl"></i>
        </div>
        <p className="text-center text-gray-700 mb-4">
          Your account has been set up successfully! You can now log in and start managing your gym tasks.
        </p>
        <button
          onClick={() => navigate('/login')}
          className="w-full bg-black text-white p-2 rounded hover:bg-gray-800"
        >
          Go to Login
        </button>
      </div>
    </div>
  );
};

export default StaffAccountSuccess;