import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement, 
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

// Register required Chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const MemberAttendance = () => {
  const { memberId } = useParams();
  const [keyFobs, setKeyFobs] = useState([]);
  const [selectedKeyFob, setSelectedKeyFob] = useState("");
  const [currentKeyFob, setCurrentKeyFob] = useState(null);
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showKeyFobForm, setShowKeyFobForm] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch attendance history
  useEffect(() => {
    fetchAttendanceRecords();
    fetchCurrentKeyFob();
  }, []);

  const fetchAttendanceRecords = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}attendance/member/${memberId}/records/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setAttendanceRecords(response.data);
    } catch (err) {
      console.error("Error fetching attendance records:", err);
    }
  };

  const fetchCurrentKeyFob = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}attendance/member/${memberId}/keyfob/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setCurrentKeyFob(response.data.keyfob_number);
    } catch (err) {
      console.error("Error fetching current key fob:", err);
      setCurrentKeyFob(null);
    }
  };

  // Search for unassigned key fobs
  const searchKeyFobs = async (query) => {
    if (!query) {
      setKeyFobs([]);
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}attendance/search-keyfobs/?query=${query}`, {
        headers: { Authorization: `Token ${token}` },
      });
      setKeyFobs(response.data);
    } catch (err) {
      console.error("Error searching key fobs:", err);
    }
  };

  // Assign or Update key fob
  const assignOrUpdateKeyFob = async () => {
    if (!selectedKeyFob) {
      toast.error("Please select a key fob.");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `${API_URL}attendance/assign-keyfob/${memberId}/`,
        { keyfob_number: selectedKeyFob },
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      toast.success("Key fob assigned successfully!");
      setCurrentKeyFob(selectedKeyFob);
      setSelectedKeyFob("");
      setShowKeyFobForm(false);
      fetchAttendanceRecords();
    } catch (err) {
      console.error("Error assigning key fob:", err);
      toast.error("Failed to assign key fob.");
    }
  };

  // Process attendance data for weekly bar chart
  const processAttendanceData = () => {
    const weeklyData = {};

    attendanceRecords.forEach((record) => {
      if (record.checkin_time && record.checkout_time) {
        const checkin = new Date(record.checkin_time);
        const checkout = new Date(record.checkout_time);
        const dateKey = checkin.toLocaleDateString("en-US", { weekday: "short", day: "numeric", month: "short" });

        const timeSpent = (checkout - checkin) / (1000 * 60 * 60); // Convert milliseconds to hours

        if (weeklyData[dateKey]) {
          weeklyData[dateKey] += timeSpent;
        } else {
          weeklyData[dateKey] = timeSpent;
        }
      }
    });

    return {
      labels: Object.keys(weeklyData),
      datasets: [
        {
          label: "Hours Spent in Gym",
          data: Object.values(weeklyData),
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <h2 className="text-xl font-bold mb-4">Member Attendance</h2>

      {/* Display Assigned Key Fob */}
      {currentKeyFob ? (
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Current Key Fob: {currentKeyFob}</h3>
          {!showKeyFobForm && (
            <button
              onClick={() => setShowKeyFobForm(true)}
              className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-400"
            >
              Update Key Fob
            </button>
          )}
        </div>
      ) : (
        <div className="mb-4">
          <h3 className="text-lg font-semibold">No Key Fob Assigned</h3>
          {!showKeyFobForm && (
            <button
              onClick={() => setShowKeyFobForm(true)}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-400"
            >
              Assign Key Fob
            </button>
          )}
        </div>
      )}

      {/* Assign/Update Key Fob Form */}
      {showKeyFobForm && (
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search key fob..."
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              searchKeyFobs(e.target.value);
            }}
            className="w-full p-2 border rounded mb-2"
          />
          {keyFobs.length > 0 && (
            <select
              onChange={(e) => setSelectedKeyFob(e.target.value)}
              className="w-full p-2 border rounded mb-2"
            >
              <option value="">Select Key Fob</option>
              {keyFobs.map((fob) => (
                <option key={fob.id} value={fob.keyfob_number}>
                  {fob.keyfob_number}
                </option>
              ))}
            </select>
          )}
          <button
            onClick={assignOrUpdateKeyFob}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
          >
            {currentKeyFob ? "Update Key Fob" : "Assign Key Fob"}
          </button>
        </div>
      )}

      {/* Attendance History Graph */}
      <div>
        <h3 className="text-lg font-semibold mb-4">Weekly Gym Attendance</h3>
        {attendanceRecords.length > 0 ? (
          <Bar data={processAttendanceData()} options={{ responsive: true }} />
        ) : (
          <p>No attendance records found.</p>
        )}
      </div>
    </div>
  );
};

export default MemberAttendance;