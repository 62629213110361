import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const StaffPassSetup = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { unique_string } = useParams();
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccessMessage('');
    setLoading(true);

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      // Prepare FormData for file upload
      const formData = new FormData();
      formData.append('new_password', newPassword);
      formData.append('confirm_password', confirmPassword);
      formData.append('agree_terms', agreeTerms);
      if (profilePhoto) {
        formData.append('profile_photo', profilePhoto);
      }

      const response = await axios.post(
        `${API_URL}users/invite-email/staff/${unique_string}/`, // ✅ Ensure correct API route
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        setSuccessMessage('Account successfully set up');
        setTimeout(() => {
          setLoading(false);
          navigate('/staff-account-success');
        }, 1000);
      } else {
        setError('Error setting up account');
        setLoading(false);
      }
    } catch (err) {
      console.error('Error setting up account:', err.response?.data || err.message);
      setError(err.response?.data?.error || 'Error setting up account');
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 h-screen flex items-center justify-center">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
        <h1 className="text-3xl font-bold mb-6 text-center">Set Up Your Staff Account</h1>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        {successMessage && <p className="text-green-500 text-center mb-4">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold">New Password</label>
            <input
              type="password"
              className="w-full p-2 border rounded"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold">Confirm Password</label>
            <input
              type="password"
              className="w-full p-2 border rounded"
              placeholder="Confirm your password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          {/* <div className="mb-4">
            <label className="block text-gray-700 font-bold">Profile Photo (Optional)</label>
            <input
              type="file"
              className="w-full p-2 border rounded"
              onChange={(e) => setProfilePhoto(e.target.files[0])}
            />
          </div> */}
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={agreeTerms}
              onChange={(e) => setAgreeTerms(e.target.checked)}
              required
            />
            <label className="ml-2 text-gray-700">I agree to the Terms of Service</label>
          </div>
          <button type="submit" className="w-full bg-black text-white p-2 rounded hover:bg-gray-800">
            {loading ? 'Setting Up...' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default StaffPassSetup;