import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const MemberTransactions = () => {
  const { memberId } = useParams();
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 5;

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${API_URL}transactions/member/${memberId}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setTransactions(response.data || []);
      } catch (error) {
        console.error("Error fetching member transactions:", error);
      }
    };

    fetchTransactions();
  }, [API_URL, memberId]);

  const formatDate = (dateStr) => {
    return new Date(dateStr).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  // Pagination logic
  const indexOfLast = currentPage * transactionsPerPage;
  const indexOfFirst = indexOfLast - transactionsPerPage;
  const paginatedTransactions = transactions.slice(indexOfFirst, indexOfLast);
  const totalPages = Math.ceil(transactions.length / transactionsPerPage);

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h2 className="text-xl font-bold mb-4">Transaction History</h2>

      {transactions.length === 0 ? (
        <p className="text-gray-600">No transactions found.</p>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-4 text-left">Amount Paid</th>
                  <th className="py-3 px-4 text-left">Balance</th>
                  <th className="py-3 px-4 text-left">Last Payment Date</th>
                  <th className="py-3 px-4 text-left">Next Payment Date</th>
                  <th className="py-3 px-4 text-left">Payment Method</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {paginatedTransactions.map((tx) => (
                  <tr key={tx.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-4">Ksh {parseFloat(tx.amount_paid).toFixed(2)}</td>
                    <td className="py-3 px-4">Ksh {parseFloat(tx.balance || 0).toFixed(2)}</td>
                    <td className="py-3 px-4">{tx.payment_date ? formatDate(tx.payment_date) : "N/A"}</td>
                    <td className="py-3 px-4">{tx.next_expected_payment_date ? formatDate(tx.next_expected_payment_date) : "N/A"}</td>
                    <td className="py-3 px-4">{tx.payment_method || "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="flex justify-end mt-4 space-x-2">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                onClick={() => setCurrentPage(i + 1)}
                className={`px-3 py-1 rounded ${currentPage === i + 1 ? "bg-blue-500 text-white" : "bg-gray-200"}`}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MemberTransactions;